import { READER_BOOK_LIST } from 'store/actions'

const initialState = {
  books: [],
}

const readerReducer = (state = initialState, action) => {
  let values = action.payload

  switch (action.type) {
    case READER_BOOK_LIST:
      return {
        ...state,
        books: values,
      }

    default:
      return state
  }
}

export default readerReducer
