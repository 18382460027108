import { useState, useEffect, useRef } from 'react'

type UseElementResizeResponse = {
  isResizing: boolean
  width: number
  height: number
}

type useElementResizeOptions = {
  element: HTMLElement | null
  onResizeStart?: (element: HTMLElement | null) => void
  onResizeEnd?: (width: number, height: number) => void
  timeout?: number
}

export const useElementResize = ({
  element,
  timeout,
  onResizeStart,
  onResizeEnd,
}: useElementResizeOptions): UseElementResizeResponse => {
  const observer = useRef<ResizeObserver>()
  const timer = useRef<number | null>(null)
  const [isElementResizing, setElementResizing] = useState(false)
  const [elementSize, setElementSize] = useState({
    width: element?.clientWidth || 0,
    height: element?.clientHeight || 0,
  })

  const onResize: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
    if (timer.current) {
      window.clearTimeout(timer.current)
    } else {
      if (typeof onResizeStart === 'function') {
        onResizeStart(element)
      }
    }
    setElementResizing(true)
    timer.current = window.setTimeout(() => {
      if (!observer.current) {
        return
      }
      setElementResizing(false)
      entries.forEach((entry) => {
        setElementSize({ width: entry.contentRect.width, height: entry.contentRect.height })
        if (typeof onResizeEnd === 'function') {
          onResizeEnd(entry.contentRect.width, entry.contentRect.height)
        }
        if (timer.current) {
          window.clearTimeout(timer.current)
          timer.current = null
        }
      })
    }, timeout ?? 500)
  }

  useEffect(() => {
    if (element) {
      observer.current = new ResizeObserver(onResize)
      observer.current.observe(element)
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect()
        observer.current = undefined
      }
    }
  }, [element])

  return {
    isResizing: isElementResizing,
    width: elementSize.width,
    height: elementSize.height,
  }
}
