import React from 'react'
import { MdSync } from 'react-icons/md'
import styled, { keyframes } from 'styled-components'

export const Loader = ({ dark = false, fit = false }) => {
  const iconStyle = { fontSize: '4rem', color: '#262626' }

  if (dark) {
    iconStyle.color = '#FFFFFF'
  }

  return (
    <Container dark={dark} fit={fit}>
      <LoadingIcon style={iconStyle} />
    </Container>
  )
}

const Container = styled.div`
  background-color: ${(p) => (p.dark ? '' : 'rgba(255,255,255, 0.9)')};
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: ${(p) => (p.fit ? '100%' : '100vh')};
`

const RotationAnimation = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const LoadingIcon = styled(MdSync)`
  animation: ${RotationAnimation} 2s linear 0s infinite;
`

const blackdots = keyframes`
  0%, 20% {
    color: rgba(255,255,255,0);
    text-shadow:
      .25em 0 0 rgba(255,255,255,0),
      .5em 0 0 rgba(255,255,255,0);}
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(255,255,255,0),
      .5em 0 0 rgba(255,255,255,0);}
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(255,255,255,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;}
`

const whitedots = keyframes`
0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}
`

export const Loading = styled.div`
  color: ${(p) => (p.dark ? 'white' : 'black')};
  display:flex;
  font-weight: 300;
  font-size: 5rem;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${(p) => (p.dark ? '' : 'rgba(255,255,255, 0.9)')};
  &:after {
    content: ' .';
    animation: ${(p) => (p.dark ? whitedots : blackdots)} 0.5s steps(5, end) infinite };
  }
  `
