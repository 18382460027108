import { useCallback, useEffect, useRef, useState } from 'react'

type Page = {
  ref: HTMLElement | null
  pageNumber: number
  visibility: number
}

type UseCurrentPageOptions = {
  scrollElement: Element | null
}

export const usePagesContainer = (options: UseCurrentPageOptions) => {
  const [visiblePages, setVisiblePages] = useState<Page[]>([])
  const [currentPage, changePage] = useState(1)
  const pages = useRef<Record<string, Page>>({})
  const observerRef = useRef<IntersectionObserver | null>(null)
  const disableObserver = useRef(false)

  useEffect(() => {
    if (observerRef.current === null) {
      /**
       * Observe pages positions when user scrolls the view.
       *
       * thresold: what are the points where page visibility should be reported.
       * 1 = 100% visible, 0 = unvisible
       */
      observerRef.current = new IntersectionObserver(handleIntersect, {
        root: options.scrollElement,
        threshold: [0, 0.2, 0.5, 0.7, 1],
      })
    }
    return () => {
      if (observerRef.current) {
        // Unobserve all pages
        observerRef.current.disconnect()
      }
    }
  }, [])

  const handleIntersect = (entries: IntersectionObserverEntry[]) => {
    if (disableObserver.current) {
      return
    }

    /**
     * Update each page visibility and
     * calculate most visible pages
     */
    entries.forEach((ent) => {
      // react-pdf adds data-page-number attribute to each page..
      const pageNum = ent.target.getAttribute('data-page-number')
      if (pageNum && pages.current[pageNum]) {
        pages.current[pageNum].visibility = ent.intersectionRatio
      }
    })

    const visiblePages = Object.keys(pages.current).reduce((visibleList, pageNumber) => {
      const page = pages.current[pageNumber]
      if (page.visibility > 0) {
        return [
          ...visibleList,
          {
            ref: page.ref,
            pageNumber: parseInt(pageNumber),
            visibility: page.visibility,
          },
        ]
      }
      return visibleList
    }, [] as Page[])

    visiblePages.sort((a, b) => b.visibility - a.visibility)

    const mostVisiblePage = visiblePages[0]?.pageNumber

    setVisiblePages(visiblePages)

    changePage((prevPageNumber) => {
      return prevPageNumber === mostVisiblePage || !mostVisiblePage ? prevPageNumber : mostVisiblePage
    })
  }

  const setObserverDisabled = (value: boolean) => {
    disableObserver.current = value
  }

  /**
   * Add new page to our pages list. We can
   * access page refs by pagenumber for scrolling
   * and detecting current page number.
   */
  const registerPage = useCallback(
    (pageNumber: number) => (ref: HTMLElement | null) => {
      // Page already registered..
      if (ref === null || pages.current[pageNumber]) {
        return
      }
      pages.current[pageNumber] = {
        ref,
        pageNumber,
        visibility: 0,
      }
      observerRef.current!.observe(ref)
    },
    []
  )

  const getPageRef = (pageNumber: number) => pages.current[pageNumber].ref

  /**
   * Remove page from observable list
   */
  const unRegisterPage = useCallback((pageNumber: number) => {
    const pageRef = pages.current[pageNumber]?.ref
    if (pageRef) {
      observerRef.current!.unobserve(pageRef)
    }
    delete pages.current[pageNumber]
  }, [])

  const setCurrentPage = (pageNumber: number) => {
    if (!pages.current[pageNumber]?.ref) {
      return
    }
    /**
     * Mock page visibility: only target page
     * should have 100% visibility after the movement.
     */
    Object.keys(pages.current).forEach(
      (pnum) => (pages.current[pnum].visibility = pnum == pageNumber.toString() ? 1 : 0)
    )
    setObserverDisabled(true)
    pages.current[pageNumber].ref?.scrollIntoView({
      behavior: 'auto',
      block: 'start', // Vertical aligment
    })
    changePage(pageNumber)
    setObserverDisabled(false)
  }

  return {
    visiblePages,
    registerPage,
    unRegisterPage,
    currentPage,
    setCurrentPage,
    getPageRef,
    setObserverDisabled,
  }
}
