import { pick } from 'src/util'

export const emptyAction = (type) => {
  return (dispatch) => {
    return () => {
      dispatch({ type })
    }
  }
}

export const payloadAction = (type, projection = []) => {
  return (dispatch) => {
    return (payload) => {
      if (projection.length > 0) {
        return dispatch({ type, payload: pick(payload, projection) })
      }
      return dispatch({ type, payload })
    }
  }
}

export const actionCreator = (t) => ({ type: t })

export const withPayload = (type, payload) => ({
  type: type,
  payload: payload,
})
