import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { apiGET, apiPOST, apiPUT, apiDELETE } from 'src/api'
import {
  OrganizationBook,
  OrganizationActions,
  Organizations,
  Organization,
  UPDATE_ORGANIZATION_USERS,
  TOGGLE_ORGANIZATION_CREATION,
  Portal,
  fetchBookAttachments,
  fetchUsers,
  UPDATE_USERS,
  fetchDistributors,
  setPortalIsLoading,
} from 'store/actions'
import { pick } from 'src/util'
import { fetchReaderCandidates } from './book'
import { isValueDuration, durationToSeconds } from 'src/util'
import { ProductionTypes } from '../../typings'
export const getDirtyBook = (state) => state.portal.adminView.dirtyBook

function* fetchFromNothing(action) {
  const { organizationId, isbn } = action.payload
  yield put({ type: Portal.LOADING_BOOK, payload: { isbn } })
  yield fetchSingleOrganization(action)
  yield all([
    fetchUsers('READER'),
    fetchReaderCandidates({ payload: { book: action.payload } }),
    fetchDistributors(),
    fetchBookAttachments({ organizationId, isbn }),
    fetchOrganizationBook(action),
  ])
  yield put({ type: Portal.LOADING_BOOK_DONE, payload: { isbn } })
}

function* fetchAllOrganizations() {
  let res
  try {
    res = yield call(apiGET, `/organizations`)
  } catch (e) {
    console.error(e)
  } finally {
    yield put({ type: Organizations.RECEIVE, payload: res.organizations })
  }
}

function* fetchSingleOrganization(action) {
  const { organizationId } = action.payload
  try {
    const organization = yield call(apiGET, `/organizations/${organizationId}`)
    yield put({ type: Organization.RECEIVE, payload: organization })
  } catch (e) {
    console.error(e)
  }
}

function* createOrganization(action) {
  const { organization } = action.payload
  const organizationRecord = pick(organization, ['name', 'contactEmails', 'receiveConfirmations', 'productionTypes'])

  organizationRecord.productionTypes =
    organizationRecord.productionTypes === ProductionTypes.SILENCIO_AANINEN
      ? [ProductionTypes.SILENCIO, ProductionTypes.AANINEN]
      : [organizationRecord.productionTypes]

  let newOrganization

  try {
    newOrganization = yield call(apiPOST, '/organizations', organizationRecord)
  } catch (e) {
    // const message = e.response ? e.response.data.message : e.message;
    // errorAction({ form: 'organization', message: message });
    console.error(e)
  } finally {
    yield put({ type: TOGGLE_ORGANIZATION_CREATION })
    yield put({ type: Organization.RECEIVE, payload: newOrganization })
  }
}

function* saveOrganization(action) {
  const { organization } = action.payload
  let updatedOrg

  const organizationRecord = pick(organization, [
    'name',
    'contactEmails',
    'receiveConfirmations',
    'defaultDistributors',
    'productionTypes',
  ])

  if (organization.productionTypes.includes('_')) {
    organizationRecord.productionTypes = organization.productionTypes.split('_')
  } else {
    organizationRecord.productionTypes = [organization.productionTypes]
  }

  try {
    updatedOrg = yield call(apiPUT, `/organizations/${organization.id}`, organizationRecord)
  } catch (e) {
    console.error(e)
  } finally {
    yield put({ type: Organization.RECEIVE, payload: updatedOrg })
  }
}

export function* fetchOrganizationBook(action) {
  let book
  const { organizationId, isbn } = action.payload
  try {
    book = yield call(apiGET, `/organizations/${organizationId}/audiobooks/${isbn}`)
    // console.log(`> Got Book ${book}`)
    const updatedBook = { ...book, organizationId, id: isbn }
    yield put({ type: OrganizationBook.RECEIVE, payload: updatedBook })
  } catch (e) {
    console.error(e)
  }
}

function* fetchOrganizationBookDistributions(action) {
  const { organizationId, isbn } = action.payload
  try {
    const res = yield call(apiGET, `/organizations/${organizationId}/audiobooks/${isbn}/distributions`)
    if (res) {
      const { distributions } = res
      const distributionsWithOrgId = []
      for (let i in distributions) {
        distributionsWithOrgId.push({ ...distributions[i], organizationId })
      }
      yield put({ type: OrganizationBook.UPDATE_DISTRIBUTIONS, payload: { distributions: distributionsWithOrgId } })
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Fetch all books for organization. with the parameter 'fetchAlsoArchived' set to true also the production ready books are fetched.
 *
 * @param {*} action - action.payload contains the method parameters
 */
function* fetchOrganizationBooks(action) {
  const { fetchAlsoArchived, id } = action.payload
  let res
  try {
    yield put(setPortalIsLoading(true))
    res = yield call(apiGET, `/organizations/${id}/audiobooks`, { archived: fetchAlsoArchived })
  } catch (e) {
    yield put({ type: Organization.REQUEST_FAIL })
    console.error(e)
  } finally {
    if (res && res.audiobooks) {
      yield put({ type: Organization.RECEIVE_BOOKS, payload: res.audiobooks })
    }
    yield put(setPortalIsLoading(false))
  }
}

function* addPublisher(action) {
  const { organizationId, name } = action.payload
  try {
    yield call(apiPOST, `/organizations/${organizationId}/publishers`, {
      name: name,
      inactive: false,
    })
  } catch (e) {
    console.error(e)
  } finally {
    yield fetchSingleOrganization(action)
  }
}

function* updatePublisher(action) {
  const { publisher } = action.payload
  const deletedPublisher = pick(publisher, ['name', 'inactive'])
  try {
    yield call(apiPUT, `/organizations/${publisher.organizationId}/publishers/${publisher.id}`, deletedPublisher)
  } catch (e) {
    console.error(e)
  } finally {
    yield put({ type: Organization.FETCH, payload: { organizationId: publisher.organizationId } })
  }
}

/**
 * Update an existing book
 * @param {*} action - contains the Saga action and the payload (book object)
 */
function* saveOrganizationBook(action) {
  let book = yield select(getDirtyBook) // read from the store if not found use the payload
  if (!book) {
    book = action.payload.book
  }

  const bookRecord = pick(book, [
    'state',
    'name',
    'author',
    'translator',
    'narrator',
    'info',
    'year',
    'celiaRequested',
    'celiaStatus',
    'productionModel',
    'corrections',
    'materialDelivery',
    'readerProgress',
    'workStartDate',
    'isBacklist',
    'correctionsBooked',
    'requestedDelivery',
    'verificationDone',
    'charCount',
    'pageCount',
    'organizationInfo',
    'readerComments',
    'readerDuration',
    'verifier',
    'invoiced',
    'celiaInvoiced',
    'verifierSalaryPaid',
    'narratorSalaryPaid',
    'publisherId',
    'correctionsDuration',
    'productionType',
  ])

  bookRecord.materialDelivery = book.materialDelivery ? new Date(book.materialDelivery) : undefined
  bookRecord.requestedDelivery = book.requestedDelivery ? new Date(book.requestedDelivery) : undefined
  bookRecord.invoiced = book.invoiced ? new Date(book.invoiced) : undefined
  bookRecord.celiaInvoiced = book.celiaInvoiced ? new Date(book.celiaInvoiced) : undefined
  bookRecord.verifierSalaryPaid = book.verifierSalaryPaid ? new Date(book.verifierSalaryPaid) : undefined
  bookRecord.narratorSalaryPaid = book.narratorSalaryPaid ? new Date(book.narratorSalaryPaid) : undefined
  bookRecord.charCount = bookRecord.charCount ? parseInt(bookRecord.charCount) : undefined
  bookRecord.pageCount = bookRecord.pageCount ? parseInt(bookRecord.pageCount) : undefined
  bookRecord.readerDuration =
    bookRecord.readerDuration && isValueDuration(bookRecord.readerDuration)
      ? durationToSeconds(bookRecord.readerDuration)
      : undefined
  bookRecord.readerProgress = bookRecord.readerProgress ? parseInt(bookRecord.readerProgress) : undefined

  let correctionsDuration = bookRecord.correctionsDuration
  if (typeof bookRecord.correctionsDuration === 'string') {
    correctionsDuration = Number(bookRecord.correctionsDuration?.replace(/[^0-9]/g, ''))
  }
  bookRecord.correctionsDuration = bookRecord.state === 'ORDERED' ? 0 : correctionsDuration

  // When user has cleared the input 'corrections booked' or it is otherwise empty
  bookRecord.correctionsBooked =
    bookRecord.correctionsBooked === null ? `0001-01-01T00:00:00Z` : new Date(bookRecord.correctionsBooked)

  try {
    const updatedBook = yield call(
      apiPUT,
      `/organizations/${book.organizationId}/audiobooks/${book.isbn}`,
      bookRecord,
      // Handle concurrent audiobook edits
      { headers: { 'If-Unmodified-Since': book.lastUpdated } },
      // Don't automatically show error toast (handle it in catch instead)
      false
    )
    if (updatedBook) {
      yield put({
        type: OrganizationBook.RECEIVE,
        payload: {
          ...updatedBook,
          organizationId: book.organizationId,
          id: updatedBook.isbn,
        },
      })
    }
  } catch (e) {
    const { response } = e
    console.error('> Failed to save audiobook', e, response)
    if (response.status === 409) {
      yield put({ type: Portal.ERROR_409, payload: { callingFunction: 'saveOrganizationBook' } })
    } else {
      const message = response ? response.data.message : e.message
      yield put({ type: Portal.ERROR_GENERAL, payload: message })
    }
  }
}

function* deleteOrganizationBook(action) {
  const { organizationId, isbn } = action.payload
  let res
  try {
    res = yield call(apiDELETE, `/organizations/${organizationId}/audiobooks/${isbn}`)
  } catch (e) {
    console.error(e)
  } finally {
    const { status } = res
    if (status === 'OK') {
      yield put({ type: 'REMOVE_BOOK', payload: { organizationId, id: isbn } })
    }
  }
}

function* fetchOrganizationUsers(action) {
  const { organizationId } = action.payload
  let users
  try {
    users = yield call(apiGET, `/organizations/${organizationId}/users`)
  } catch (e) {
    console.log(`> Failed to fetch orgs ${organizationId} users`, e)
  } finally {
    console.log(`> Received orgs ${organizationId} users`, users)
    yield put({ type: UPDATE_USERS, payload: users })
    yield put({ type: UPDATE_ORGANIZATION_USERS, payload: { organizationId, users } })
  }
}

function* updateReaderComments(action) {
  const { organizationId, isbn, lastUpdated, readerComments } = action.payload
  try {
    yield call(
      apiPUT,
      `/organizations/${organizationId}/audiobooks/${isbn}`,
      { readerComments },
      { headers: { 'If-Unmodified-Since': lastUpdated } }
    )
    yield put({ type: 'FETCH_READER_BOOKS' })
  } catch (error) {
    console.error(error)
  }
}

function* updateListenerComments(action) {
  const { organizationId, isbn, lastUpdated, listenerComments } = action.payload
  try {
    yield call(
      apiPUT,
      `/organizations/${organizationId}/audiobooks/${isbn}`,
      { listenerComments },
      { headers: { 'If-Unmodified-Since': lastUpdated } }
    )
    yield put({ type: 'FETCH_CORRECTION_BOOKS' })
  } catch (error) {
    console.error(error)
  }
}

function* organizationSaga() {
  yield takeLatest(Organization.CREATE, createOrganization)
  yield takeLatest(Organization.FETCH, fetchSingleOrganization)
  yield takeLatest(Organization.SAVE, saveOrganization)
  yield takeLatest(Organization.FETCH_USERS, fetchOrganizationUsers)
  yield takeLatest(Organization.ADD_PUBLISHER, addPublisher)
  yield takeLatest(Organization.UPDATE_PUBLISHER, updatePublisher)
  yield takeLatest(Organizations.FETCH, fetchAllOrganizations)
  yield takeEvery(OrganizationBook.REQUEST, fetchOrganizationBook)
  yield takeLatest(OrganizationBook.FETCH_DISTRIBUTIONS, fetchOrganizationBookDistributions)
  yield takeLatest(OrganizationActions.REQUEST_BOOKS, fetchOrganizationBooks)
  yield takeLatest(OrganizationBook.SAVE, saveOrganizationBook)
  yield takeLatest(OrganizationBook.DELETE, deleteOrganizationBook)
  yield takeLatest(OrganizationBook.FROM_SCRATCH, fetchFromNothing)
  yield takeLatest(OrganizationBook.UPDATE_READER_COMMENTS, updateReaderComments)
  yield takeLatest(OrganizationBook.UPDATE_LISTENER_COMMENTS, updateListenerComments)
}

export default organizationSaga
