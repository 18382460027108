import React, { useEffect, useRef } from 'react'
import jwtDecode from 'jwt-decode'
import { connect } from 'react-redux'
import { CronJob } from 'cron'
import styled from 'styled-components'

import { api } from 'src/api'
import { firebaseAuth, bindUI, refreshToken } from 'src/firebase'
import { useDispatcher } from '../../hooks'

import Invitation from 'components/invitation'
import { Loading } from 'components/loading'

import '../../../node_modules/firebaseui/dist/firebaseui.css'

const job = new CronJob({
  cronTime: '0 */30 * * * *',
  onTick: function () {
    refreshToken()
  },
  start: false,
})

const Login = ({ loggedOut, checking, identified, loggedIn }) => {
  const domNode = useRef()

  const { userDispatcher } = useDispatcher()

  useEffect(() => {
    firebaseAuth.then((auth) =>
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken(true)
          handleToken(token)
        } else {
          handleToken(null)
        }
      })
    )
  }, [])

  useEffect(() => () => job.stop(), [])

  useEffect(() => {
    if (loggedOut) {
      userDispatcher.logout()
      bindUI(domNode.current, { signInSuccessUrl: window.location.pathname })
    }
  }, [loggedOut])

  const handleToken = (idToken) => {
    api.setToken(idToken)
    if (idToken) {
      job.start() // starting refresh token cron
      const { name, picture } = jwtDecode(idToken)
      api
        .get('/user', {}, false)
        .then((user) => {
          if (Object.keys(user).length === 0) {
            throw Error()
          } else {
            userDispatcher.login({ ...user, image: picture })
          }
        })
        .catch((e) => {
          console.error(e)
          userDispatcher.firebaseLogin({ name, image: picture })
        })
    } else {
      userDispatcher.logout()
    }
  }

  const renderContent = () => {
    if (checking) {
      return <Loading dark />
    } else if (loggedIn) {
      return null
    } else if (identified) {
      return <Invitation />
    } else {
      return <div style={{ marginTop: '60px' }} ref={domNode} />
    }
  }

  return <LoginContainer>{renderContent()}</LoginContainer>
}

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`

const mapStateToProps = (state) => ({
  loggedOut: state.user.state === 'LOGGED_OUT',
  loggedIn: state.user.state === 'LOGGED_IN',
  identified: state.user.state === 'IDENTIFIED',
  checking: state.user.state === 'CHECKING',
})

export default connect(mapStateToProps)(Login)
