import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'

import { UserType } from './pseudo-types'

const AdminRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (user.type) {
          case UserType.ADMIN:
            return <Component {...props} />
          case UserType.CELIA:
            return <Redirect to={{ pathname: `/celia`, state: {} }} />
          case UserType.READER:
            return <Redirect to={{ pathname: `/reader`, state: {} }} />
          case UserType.CORRECTIONS:
            return <Redirect to={{ pathname: `/corrections`, state: {} }} />
          default:
            if (user.organizationId === '') {
              console.error(`user is missing Organization ID!`)
              return
            }
            return (
              <Redirect
                to={{
                  pathname: `/organizations/${user.organizationId}`,
                  state: { from: props.location },
                }}
              />
            )
        }
      }}
    />
  )
}

const mapStateToProps = (state) => {
  const user = state.user
  return {
    user,
  }
}
export default withRouter(connect(mapStateToProps)(AdminRoute)) // withRouter is required otherwise redux connect would block path location updates https://stackoverflow.com/questions/46007214/react-router-v4-not-rendering-components
