import { useSelector } from 'react-redux'
import { pronunciationsSelector } from '../../../store/selectors'
import { PronunciationItem } from '../../../typings'
import { useDispatcher } from '../../../hooks/useDispatcher'
import { useEffect } from 'react'
import { useAutoSave } from '.'

interface SnapshotToLocalStorage {
  keyName: string
  items?: PronunciationItem[]
  limit?: number
}

interface StorageSnapshot {
  version: number
  items: string
}

export const useGuideSetHistory = (isbn: string) => {
  const { pronunciationsDispatcher } = useDispatcher()
  const { setAutosaveEnabled } = useAutoSave()

  const draftItems: PronunciationItem[] = useSelector(pronunciationsSelector.getDraftItems)

  useEffect(() => {
    localStorage.clear()
  }, [])

  const getOldestSnapshotItemKey = (): string => {
    const snapshotsKeys = Object.keys(localStorage).filter((key) => key.includes(`:${isbn}`))

    const snapshotKey = snapshotsKeys.reduce((prev, curr) =>
      Number(prev.split(':')[2]) < Number(curr.split(':')[2]) ? prev : curr
    )

    return snapshotKey
  }

  const getLatestSnapshot = () => {
    const snapshotsKeys = Object.keys(localStorage).filter((key) => key.includes(`:${isbn}`))

    if (!snapshotsKeys.length) {
      return undefined
    }

    // Compare version numbers in key name and find latest version
    const snapshotKey = snapshotsKeys.reduce((prev, curr) =>
      Number(prev.split(':')[2]) > Number(curr.split(':')[2]) ? prev : curr
    )
    const snapshot = localStorage.getItem(snapshotKey!!)
    const parsedSnapshot = JSON.parse(snapshot!!)

    return parsedSnapshot
  }

  const saveSnapshotToLocalStorage = ({ keyName, limit, items }: SnapshotToLocalStorage) => {
    const maxSnapshotLimit = limit || 20
    if (localStorage.length === maxSnapshotLimit) {
      const oldestSnapshotItemKey = getOldestSnapshotItemKey()
      localStorage.removeItem(oldestSnapshotItemKey)
    }

    let version
    const snapshot = getLatestSnapshot()
    if (snapshot) {
      version = snapshot.version + 1
    } else {
      version = 1
    }

    const payload = {
      version,
      items: items ?? draftItems,
    }

    setAutosaveEnabled(true)

    try {
      localStorage.setItem(`${keyName}:${isbn}:${version}`, JSON.stringify(payload))
    } catch (e) {
      const oldestSnapshotItemKey = getOldestSnapshotItemKey()
      localStorage.removeItem(oldestSnapshotItemKey)
      localStorage.setItem(`${keyName}:${isbn}:${version}`, JSON.stringify(payload))
    }
  }

  const getSnapshotWithVersion = (keyName: string, version: number) => {
    const snapshot = localStorage.getItem(`${keyName}:${isbn}:${version}`)
    if (!snapshot) {
      return undefined
    }
    return JSON.parse(snapshot)
  }

  const removeLatestSnapshot = (latestSnapshot: StorageSnapshot) => {
    const key = Object.keys(localStorage).find((key) => key.includes(`:${isbn}:${latestSnapshot.version}`))
    key && localStorage.removeItem(key)
  }

  const restoreSnapshotVersion = (keyName: string, version?: number) => {
    // check if user wants to restore specific version
    if (version) {
      const snapshot = getSnapshotWithVersion(keyName, version)
      if (snapshot) {
        pronunciationsDispatcher.setGuideItems(snapshot)
      }
    }
    // if no version given, restore latest version and delete used item from localstorage
    const latestSnapshot = getLatestSnapshot()
    removeLatestSnapshot(latestSnapshot)

    if (latestSnapshot) {
      pronunciationsDispatcher.setGuideItems(latestSnapshot.items)
    }
  }

  const isSnapshotEdited = (keyName: string) => {
    return Object.keys(localStorage).find((key: string) => key.startsWith(keyName))
  }

  return { saveSnapshotToLocalStorage, restoreSnapshotVersion, isSnapshotEdited }
}
