import { getOrgUsers } from './user'
import { createSelector } from 'reselect'

export const getOrganizationsUsers = (orgId) => (state) => {
  const allOrgUsers = getOrgUsers(state)
  return allOrgUsers.filter((u) => u.organizationId === orgId)
}

export const getOrganizationPublishers = (organizationId) => (state) => {
  return state.models.organizations.byId[organizationId].publishers
}

export const getOrganizationSelector = (organizationId) => (state) => {
  return state.models.organizations.byId[organizationId]
}

export const createdBookSelector = (state) => state.transient.organizationLatestBookCreated
export const filteredBookSelector = (state) => state.transient.organizationBookFilter

export const organizationSortingModeSelector = (state) =>
  state.transient.organizationSortingMethod
    ? state.transient.organizationSortingMethod
    : { id: 'requestedDelivery', desc: false }

const getOrganizations = (state) => state.models.organizations

export const getOrganizationDefaultDistributors = (id) =>
  createSelector([getOrganizations], (organizations) => {
    const byIds = Object.values(organizations.byId)
    return byIds.length > 0 ? byIds.find((org) => org.id === id)?.defaultDistributors : []
  })

export const distributeNowSelector = (state) => state.transient.distributeNow

export const getProductionTypeFilters = (state) => state.transient.productionTypeFilters
