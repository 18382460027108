import moment from './moment'
import { ReaderCandidateStates } from './pseudo-types'
import { ProductionTypes } from './typings'

export const isValueDuration = (value) => {
  const regex = /[0-9][0-9]:[0-9][0-9]:[0-9][0-9]/g
  return value.match && value.match(regex)
}

export const durationToSeconds = (hms) => {
  const array = hms.split(':')
  return +array[0] * 60 * 60 + +array[1] * 60 + +array[2]
}

export const formatDuration = (fSeconds) => {
  const totalSeconds = Math.floor(fSeconds)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds - minutes * 60
  const sSeconds = ('00' + seconds).substr(-2)
  return `${minutes}:${sSeconds}`
}

export const formatBookDuration = (fSeconds) => {
  const totalSeconds = Math.floor(fSeconds)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.round((totalSeconds % 3600) / 60)
  const sMinutes = ('00' + minutes).substr(-2)
  return `${hours}h ${sMinutes}min`
}

// Returns duration (in seconds) formatted as hh:mm:ss
export const formatDurationHMS = (duration) => {
  const hours = Math.floor(duration / 3600)
  duration %= 3600
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60

  const shouldAddZeroSuffix = (value) => (value < 10 ? `0${value}` : value)

  return `${shouldAddZeroSuffix(hours)}:${shouldAddZeroSuffix(minutes)}:${shouldAddZeroSuffix(seconds)}`
}

export const bookDurationInMinutes = (fSeconds) => {
  const totalSeconds = Math.floor(fSeconds)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.round((totalSeconds % 3600) / 60)
  const sMinutes = ('00' + minutes).substr(-2)
  return `${getMinutesFromSeconds(fSeconds)}min (${hours}h ${sMinutes}min)`
}

const getMinutesFromSeconds = (fSeconds) => {
  const totalSeconds = Math.floor(fSeconds)
  return Math.round(totalSeconds / 60)
}

export const formatDate = (date, showTime = false) => {
  if (showTime) {
    return date ? moment(date).format('L LTS') : '----'
  }
  return date ? moment(date).format('L') : '----'
}

export const timeDifferenceInMinutes = (a, b) => {
  return a.diff(b, 'minutes')
}

export const timeDifferenceInSeconds = (a, b) => {
  return a.diff(b, 'seconds')
}

export const splitDate = (date) => {
  if (!date) return
  let parts = date.split('.')
  let newDate = new Date(parts[1] + '/' + parts[0] + '/' + parts[2])
  return newDate
}

export const mapStatusToColor = (status) => {
  switch (status) {
    case 'ORDERED':
      return 'hsl(0, 0%, 95%)'
    case 'READER_CANDIDATE':
      return 'hsl(0, 4%, 74%)'
    case 'READER_CONFIRMED':
      return 'hsl(345, 39%, 72%)'
    case 'READER_BOOKED':
      return 'hsl(205, 66%, 63%)'
    case 'IN_RECORDING':
      return 'hsl(52, 98%, 62%)'
    case 'READING_DONE':
      return 'hsl(52, 98%, 62%)'
    case 'BUILT':
    case 'IN_VERIFICATION':
    case 'ON_PLAYER':
      return 'hsl(281, 100%, 65%)'
    case 'VERIFICATION_DONE':
    case 'EDITED':
      return 'hsl(36, 62%, 34%)'
    case 'DELIVERED':
      return 'hsl(135, 68%, 50%)'
    case 'BILLED':
      return 'hsl(95, 40%, 30%)'
    case 'PRODUCTION_READY':
      return 'hsl(8, 100%, 50%)'
    default:
      return 'cream'
  }
}

export const getReaderProgressPercentage = (state, readerProgress, pageCount) => {
  const mapPageCount = (pageCount, state) => {
    if (pageCount) {
      return pageCount
    } else {
      switch (state) {
        case 'ORDERED':
        case 'READER_CANDIDATE':
        case 'READER_CONFIRMED':
        case 'READER_BOOKED':
        case 'IN_RECORDING':
          return -Infinity
        case 'READING_DONE':
        case 'BUILT':
        case 'ON_PLAYER':
        case 'IN_VERIFICATION':
        case 'VERIFICATION_DONE':
        case 'EDITED':
        case 'DELIVERED':
        case 'BILLED':
          return +Infinity
        default:
          // unexpected
          return -Infinity
      }
    }
  }

  let percentage

  const mappedPageCount = mapPageCount(pageCount, state)
  if (mappedPageCount === Number.NEGATIVE_INFINITY) {
    percentage = 0
  } else if (mappedPageCount === Number.POSITIVE_INFINITY) {
    percentage = 100
  } else {
    const progress = readerProgressDisplayValue(state, readerProgress, pageCount)
    percentage = pageCount && pageCount > 0 ? 100 * (progress / pageCount) : 0
  }

  return percentage
}

export const readerProgressDisplayValue = (state, readerProgress, pageCount) => {
  switch (state) {
    case 'ORDERED':
    case 'READER_CANDIDATE':
    case 'READER_CONFIRMED':
    case 'READER_BOOKED':
      // report 0% done if before IN_RECORDING state regardless of what readerProgress says
      return 0
    case 'IN_RECORDING':
      // report progress % from readerProgress
      return readerProgress
    case 'READING_DONE':
    case 'BUILT':
    case 'ON_PLAYER':
    case 'IN_VERIFICATION':
    case 'VERIFICATION_DONE':
    case 'EDITED':
    case 'DELIVERED':
    case 'BILLED':
      // report 100% done if past IN_RECORDING state regardless of what readerProgress says
      return pageCount
    default:
      // unexpected
      return 0
  }
}

export const authorizedToFetchAttachements = (audiobookState, candidateState) => {
  const bookAuthorizedToFetchAttachements = () => {
    switch (audiobookState) {
      case 'ORDERED':
      case 'READER_CANDIDATE':
        return false
      case 'READER_CONFIRMED':
      case 'READER_BOOKED':
      case 'IN_RECORDING':
      case 'READING_DONE':
      case 'BUILT':
      case 'IN_VERIFICATION':
      case 'ON_PLAYER':
      case 'VERIFICATION_DONE':
      case 'EDITED':
      case 'DELIVERED':
      case 'BILLED':
      case 'PRODUCTION_READY':
      default:
        return true
    }
  }

  const candidateAuthorizedToFetchAttachements = () => {
    switch (candidateState) {
      case ReaderCandidateStates.Unconfirmed:
      case ReaderCandidateStates.Open:
      case ReaderCandidateStates.Rejected:
        return false
      case ReaderCandidateStates.Accepted:
      case ReaderCandidateStates.Reading:
      case ReaderCandidateStates.Done:
      default:
        return true
    }
  }

  return bookAuthorizedToFetchAttachements() && candidateAuthorizedToFetchAttachements()
}

// pick replace lodash function
export const pick = (object, keys) => {
  return keys.reduce((obj, key) => {
    // eslint-disable-next-line
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export const parseIntStrict = (value) => {
  // Start by trimming whitespace.
  const trimmed = `${value}`.trim()

  // Let's parse it.
  const parsed = parseInt(trimmed)

  // Test if parseInt thought it was an integer.
  if (isNaN(parsed)) {
    return NaN
  }

  // Okay, parseInt thinks it was an integer number...
  // ... Unfortunately parseInt thinks any number of crazy strings,
  // like "123ABC", are an integer.

  // Let's at least make sure the parsed int converts back to the original string.
  const backConversion = parsed.toString()
  if (trimmed !== backConversion) {
    // parseInt is crazy
    return NaN
  }

  return parsed
}

export const unicode = {
  nbsp: '\u00a0',
}

export const isDebugEnabled = !!process?.env?.DEBUG

export const bookInExpandableState = (row) =>
  row.original.audiobookState === 'READER_CANDIDATE' ||
  row.original.audiobookState === 'READER_CONFIRMED' ||
  row.original.audiobookState === 'READER_BOOKED' ||
  row.original.audiobookState === 'IN_RECORDING' ||
  row.original.audiobookState === 'VERIFICATION_DONE' ||
  row.original.audiobookState === 'READING_DONE'

export const formatProductionType = (productionType, t) => {
  return productionType === ProductionTypes.AANINEN ? t('aaninen') : t('silencio')
}
