import React from 'react'
import { toast } from 'react-toastify'
import { ApiStatus, commonToastOptions, Text, Toast, ToastRow } from './StyledToasts'

const ApiErrorToast = ({ error }) => (
  <Toast>
    <ToastRow>
      <ApiStatus>{error.response ? error.response.status : 'Odottamaton virhe'}</ApiStatus>
      <Text>{error.response ? error.response.data.message : error.message}</Text>
    </ToastRow>
  </Toast>
)

ApiErrorToast.options = {
  ...commonToastOptions,
  position: toast.POSITION.TOP_CENTER,
  autoClose: 10000, // 10 sec
  hideProgressBar: true,
}

export default ApiErrorToast
