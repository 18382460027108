import { SHOW_FORM_ERROR } from 'store/actions'

const initialState = {
  forms: {},
}

const messageReducer = (state = initialState, action) => {
  const values = action.payload || {}

  switch (action.type) {
    case SHOW_FORM_ERROR:
      return { ...state, forms: { ...state.forms, [values.form]: values.message } }

    default:
      return state
  }
}

export default messageReducer
