import { actionCreator, withPayload, payloadAction, emptyAction } from '../common'

export const Distributor = {
  // reducer processes these
  TOGGLE_DISTRIBUTOR_CREATION: 'TOGGLE_DISTRIBUTOR_CREATION',
  REMOVE_DISTRIBUTOR: 'REMOVE_DISTRIBUTOR',
  UPDATE_DISTRIBUTOR: 'UPDATE_DISTRIBUTOR',
  TOGGLE_DISTRIBUTOR_ROW: 'TOGGLE_DISTRIBUTOR_ROW',
}

export const updateDistributor = payloadAction(Distributor.UPDATE_DISTRIBUTOR)

export const toggleDistributorCreation = emptyAction(Distributor.TOGGLE_DISTRIBUTOR_CREATION)

export const toggleDistributorRow = payloadAction(Distributor.TOGGLE_DISTRIBUTOR_ROW)

export const removeDistributor = (props) => withPayload(Distributor.REMOVE_DISTRIBUTOR, props.id)

export const Distributors = {
  // saga listens to these
  FETCH: '@@distributors/FETCH',
  CREATE: '@@distributors/CREATE',
  SAVE: '@@distributors/SAVE',
  DELETE: '@@distributors/DELETE',
}

export const HYDRATE_DISTRIBUTORS = '@@distributors/RECEIVE'

export const fetchDistributors = () => actionCreator(Distributors.FETCH)
export const createDistributor = (distributor) => withPayload(Distributors.CREATE, distributor)
export const saveDistributor = (distributor) => withPayload(Distributors.SAVE, distributor)
export const deleteDistributor = (distributor) => withPayload(Distributors.DELETE, distributor)
