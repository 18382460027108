import {
  clearBooks,
  requestBooks,
  requestAlsoArchivedBooks,
  fetchBookData,
  fetchBookAttachments,
  fetchReaderCandidatesAction,
  fetchReaderBooklist,
  fetchCeliaBooklist,
  fetchCorrectionsBooklist,
  refreshBooksAndLoadedBook,
  clearCreatedOrganizationBook,
  deleteAttachment,
  updatettachment,
  increasePublishingPanelWidthAction,
  decreasePublishingPanelWidthAction,
  resetPublishingPanelWidthAction,
} from '.'

export class BooksDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }
  clearBooks = () => this.dispatch(clearBooks())
  fetchBookData = (book) => this.dispatch(fetchBookData(book))
  fetchBookAttachments = (book) => this.dispatch(fetchBookAttachments(book))
  fetchAllBooks = () => this.dispatch(requestBooks())
  fetchAlsoArchived = () => this.dispatch(requestAlsoArchivedBooks())
  fetchReaderCandidates = (book) => this.dispatch(fetchReaderCandidatesAction(book))
  fetchReaderBookList = () => this.dispatch(fetchReaderBooklist())
  fetchCeliaBooks = () => this.dispatch(fetchCeliaBooklist())
  fetchCorrectionBooks = () => this.dispatch(fetchCorrectionsBooklist())
  refreshBooksAndLoadedBook = (book) => this.dispatch(refreshBooksAndLoadedBook(book))
  deleteAttachment = (attachment) => this.dispatch(deleteAttachment(attachment))
  updateAttachment = (attachment) => this.dispatch(updatettachment(attachment))
  clearCreatedBook = () => this.dispatch(clearCreatedOrganizationBook())
  increasePublishingPanelWidth = () => this.dispatch(increasePublishingPanelWidthAction())
  decreasePublishingPanelWidth = () => this.dispatch(decreasePublishingPanelWidthAction())
  resetPublishingPanelWidth = () => this.dispatch(resetPublishingPanelWidthAction())
}
