import React from 'react'
import * as ReactRedux from 'react-redux'
import { isDebugEnabled } from './util'

if (isDebugEnabled) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackExtraHooks: [[ReactRedux, 'useSelector']],
  })
}
