import sortBy from 'lodash.sortby'

export const getInvitations = (state, opts = {}) => {
  const { includeAdmins = true } = opts
  const invitations = sortBy(Object.values(state.models.invitations.byId), (o) => o.email.toLowerCase())

  return includeAdmins ? invitations.filter((inv) => inv.admin) : invitations.filter((inv) => !inv.admin)
}

export const getNewInvitations = (state) => state.models.invitations.creating
