import moment from 'moment'

import { updateWithGroupedBy, updateWithId, remove, convertToMap, convertToOrganizationMap } from './common'

import {
  REMOVE_BOOK,
  RECEIVE_CELIA_BOOKS,
  RECEIVE_CORRECTION_BOOKS,
  BookActions,
  Books,
  Organization,
  OrganizationBook,
} from 'store/actions'

const initialState = {
  creating: null,
  byId: {},
  list: [],
  celiaBooks: [],
  correctionBooks: [],
  idsByOrganizationId: {},
  candidatesByBookId: {},
  publishingPanelWidth: 0.47,
}

const PUBLISHING_PANEL_WIDTH_STEP = 0.025

const updateGroupedByOrg = updateWithGroupedBy('organizationId')

const removeFromGroup = (group, groupId, id) => {
  return {
    ...group,
    [groupId]: remove(group[groupId], id),
  }
}

const booksReducer = (state = initialState, action) => {
  let values = action.payload

  switch (action.type) {
    case RECEIVE_CELIA_BOOKS:
      return {
        ...state,
        celiaBooks: values,
      }
    case RECEIVE_CORRECTION_BOOKS:
      return {
        ...state,
        correctionBooks: {
          upcoming: values.upcoming,
          claimed: values.claimed,
          pool: values.pool,
        },
      }

    case OrganizationBook.TOGGLE_CREATION:
      if (!state.creating) {
        return {
          ...state,
          creating: {
            isbn: '',
            name: '',
            author: '',
            narrator: '',
            translator: '',
            year: moment().format('YYYY'),
            state: 'ORDERED',
            productionModel: 'D',
            celiaRequested: false,
            corrections: 'SILENCIO',
            charCount: 0,
            pageCount: 0,
            duration: 0,
            chapters: [],
            creating: true,
          },
        }
      }
      return {
        ...state,
        creating: null,
      }

    case OrganizationBook.RECEIVE:
      if (values.creating === true) {
        return {
          ...state,
          creating: values,
        }
      }

      return {
        ...state,
        byId: updateWithId(state.byId, values),
        idsByOrganizationId: updateGroupedByOrg(state.idsByOrganizationId, values),
      }

    case REMOVE_BOOK: {
      const {
        byId: { [values.id]: keyToBeRemove, ...restOfKeys }, // eslint-disable-line
      } = state
      return {
        ...state,
        byId: restOfKeys,
        idsByOrganizationId: removeFromGroup(state.idsByOrganizationId, values.organizationId, values.id),
      }
    }

    case Organization.RECEIVE_BOOKS:
      return {
        ...state,
        byId: convertToMap(values),
        idsByOrganizationId: convertToOrganizationMap(values),
      }

    case Books.RECEIVE:
      return {
        ...state,
        byId: convertToMap(values),
        idsByOrganizationId: convertToOrganizationMap(values),
      }

    case Books.CLEAR:
      return {
        byId: {},
        list: [],
        celiaBooks: [],
        correctionBooks: [],
        idsByOrganizationId: {},
        candidatesByBookId: {},
        publishingPanelWidth: 0.5,
      }

    case BookActions.UPDATE_READER_CANDIDATES: {
      const { bookId, candidates } = values
      return {
        ...state,
        candidatesByBookId: {
          ...state.candidatesByBookId,
          [bookId]: candidates,
        },
      }
    }

    case BookActions.ADD_READER_CANDIDATE: {
      const { bookId, candidate } = values
      const existing = state.candidatesByBookId[bookId] || []
      return {
        ...state,
        candidatesByBookId: {
          ...state.candidatesByBookId,
          [bookId]: existing.concat(candidate),
        },
      }
    }

    case BookActions.REMOVE_READER_CANDIDATE: {
      const { bookId, candidateId } = values
      return {
        ...state,
        candidatesByBookId: {
          ...state.candidatesByBookId,
          [bookId]: state.candidatesByBookId[bookId].filter((c) => c.id !== candidateId),
        },
      }
    }
    case BookActions.INCREASE_PUBLISHING_PANEL_WIDTH: {
      // Increase width 5%
      const newWidth = state.publishingPanelWidth + PUBLISHING_PANEL_WIDTH_STEP
      return {
        ...state,
        publishingPanelWidth: newWidth,
      }
    }
    case BookActions.DECREASE_PUBLISHING_PANEL_WIDTH: {
      // Decrease width 5%
      const newWidth = state.publishingPanelWidth - PUBLISHING_PANEL_WIDTH_STEP
      return {
        ...state,
        publishingPanelWidth: newWidth,
      }
    }
    case BookActions.RESET_PUBLISHING_PANEL_WIDTH: {
      return {
        ...state,
        publishingPanelWidth: initialState.publishingPanelWidth,
      }
    }

    default:
      return state
  }
}

export default booksReducer
