import { updateWithGroupedBy, updateManyWithGroupedBy, updateWithId, updateManyWithId, remove } from './common'
import { OrganizationBook, TOGGLE_DISTRIBUTION_CREATION, REMOVE_DISTRIBUTION } from 'store/actions'

const initialState = {
  creating: null,
  byId: {},
  idsByBookId: {},
  idsByOrganizationId: {},
}

const updateGroupedByOrg = updateWithGroupedBy('organizationId')
const updateGroupedByISBN = updateWithGroupedBy('bookIsbn')

const updateManyGroupedByOrg = updateManyWithGroupedBy('organizationId')
const updateManyGroupedByISBN = updateManyWithGroupedBy('bookIsbn')

const distributionsReducer = (state = initialState, action) => {
  let newDistribution
  const values = action.payload

  switch (action.type) {
    case TOGGLE_DISTRIBUTION_CREATION:
      if (!state.creating) {
        return {
          ...state,
          creating: {
            scheduled: '',
            immediate: false,
            distributors: [],
            creating: true,
          },
        }
      }
      return {
        ...state,
        creating: null,
      }

    case OrganizationBook.UPDATE_DISTRIBUTION:
      newDistribution = action.payload

      if (newDistribution.creating === true) {
        return {
          ...state,
          creating: newDistribution,
        }
      }

      return {
        byId: updateWithId(state.byId, newDistribution),
        idsByBookId: updateGroupedByISBN(state.idsByBookId, newDistribution),
        idsByOrganizationId: updateGroupedByOrg(state.idsByOrganizationId, newDistribution),
      }
    case OrganizationBook.UPDATE_DISTRIBUTIONS: {
      const { distributions } = action.payload

      return {
        byId: updateManyWithId(state.byId, distributions),
        idsByBookId: updateManyGroupedByISBN(state.idsByBookId, distributions),
        idsByOrganizationId: updateManyGroupedByOrg(state.idsByOrganizationId, distributions),
      }
    }
    case REMOVE_DISTRIBUTION: {
      //removing key from object by using object deconstruct
      const {
        // eslint-disable-next-line no-unused-vars
        byId: { [values.id]: keyToBeRemove, ...restOfKeys },
      } = state // eslint-disable-line
      return {
        ...state,
        byId: restOfKeys,
        idsByBookId: Object.assign({}, state.idsByBookId, {
          [values.bookIsbn]: remove(state.idsByBookId[values.bookIsbn], values.id),
        }),
        idsByOrganizationId: Object.assign({}, state.idsByOrganizationId, {
          [values.organizationId]: remove(state.idsByOrganizationId[values.organizationId], values.id),
        }),
      }
    }
    default:
      return state
  }
}

export default distributionsReducer
