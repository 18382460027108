import { LOGIN, LOGOUT, FIREBASE_LOGIN, UPDATE_USERS, REMOVE_USER, WRITE_USER } from 'store/actions'
import { UserType } from '../../pseudo-types'

import { updateManyWithId, removeWithId, updateWithId } from './common'

export const CHECKING = 'CHECKING'
export const IDENTIFIED = 'IDENTIFIED'
export const LOGGED_IN = 'LOGGED_IN'
export const LOGGED_OUT = 'LOGGED_OUT'

const initialState = {
  id: '',
  name: '',
  email: '',
  image: '',
  admin: false,
  organizationId: null,
  state: CHECKING,
  type: UserType.GUEST,
  byId: {},
}

const userReducer = (state = initialState, action) => {
  const values = action.payload || {}
  switch (action.type) {
    case FIREBASE_LOGIN:
      return { ...state, ...values, state: IDENTIFIED }

    case LOGIN:
      return { ...state, ...values, state: LOGGED_IN }

    case LOGOUT:
      return { ...state, name: '', image: '', state: LOGGED_OUT }

    case UPDATE_USERS:
      return { ...state, byId: updateManyWithId(state.byId, action.payload) }

    case REMOVE_USER:
      return { ...state, byId: removeWithId(state.byId, action.payload) }

    case WRITE_USER:
      return { ...state, byId: updateWithId(state.byId, action.payload) }
    default:
      return state
  }
}

export default userReducer
