import { updateWithId } from './common'
import { TOGGLE_INVITATION_CREATION, UPDATE_INVITATION } from 'store/actions'

const initialState = {
  creating: false,
  byId: {},
}

const invitationReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_INVITATION_CREATION:
      if (!state.creating) {
        return {
          ...state,
          creating: {
            email: '',
            creating: true,
            formId: action.payload,
          },
        }
      }
      return {
        ...state,
        creating: false,
      }

    case UPDATE_INVITATION:
      if (action.payload.creating === true) {
        return {
          ...state,
          creating: action.payload,
        }
      }

      return {
        ...state,
        byId: updateWithId(state.byId, action.payload),
      }

    default:
      return state
  }
}

export default invitationReducer
