import { Portal } from 'store/actions/portal'

const initialState = {
  loading: false,
  loadingBooks: [],
  loadedBooks: [],
  // TODO: use array instead
  errors: {},
  message: '',
  isMobile: window.innerWidth <= 768,
  adminView: {
    sort: [
      {
        desc: true,
        id: 'created',
      },
    ],
    dirtyBook: null,
  },
  currentView: null,
  tableVisitReturnState: {
    viewedIsbn: null,
    sortBy: null,
    filters: null,
  },
  focus: true,
}

const portalReducer = (state = initialState, action) => {
  switch (action.type) {
    case Portal.MOBILE_MODE: {
      return {
        ...state,
        isMobile: action.payload,
      }
    }
    case Portal.MESSAGE: {
      return {
        ...state,
        message: action.payload,
      }
    }
    case Portal.CLEAR_MESSAGES: {
      return {
        ...state,
        message: '',
      }
    }
    case Portal.CLEAR_ERRORS: {
      return {
        ...state,
        errors: {},
      }
    }
    case Portal.ERROR_409: {
      return {
        ...state,
        message: action.payload,
        errors: {
          ...state.errors,
          error409: true,
        },
      }
    }
    case Portal.ERROR_GENERAL: {
      return {
        ...state,
        errors: {
          ...state.errors,
          general: action.payload,
        },
      }
    }
    case Portal.LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case Portal.LOADING_BOOK: {
      const loadingBooks = new Set(state.loadingBooks)
      loadingBooks.add(action.payload.isbn)

      return {
        ...state,
        loadingBooks: [...loadingBooks],
      }
    }
    case Portal.LOADING_BOOK_DONE: {
      const loadingBooks = new Set(state.loadingBooks)
      const loadedBooks = new Set(state.loadedBooks)
      loadingBooks.delete(action.payload.isbn)
      loadedBooks.add(action.payload.isbn)

      return {
        ...state,
        loadingBooks: [...loadingBooks],
        loadedBooks: [...loadedBooks],
      }
    }
    case Portal.CLEAR_LOADED_BOOKS: {
      return {
        ...state,
        loadedBooks: [],
      }
    }
    case Portal.EDITING_BOOK: {
      return {
        ...state,
        adminView: {
          ...state.adminView,
          dirtyBook: action.payload,
        },
      }
    }
    case Portal.ADMIN_TABLE_SORT: {
      return {
        ...state,
        adminView: {
          ...state.adminView,
          sort: action.payload,
        },
      }
    }
    case Portal.VIEW_LOAD: {
      return {
        ...state,
        currentView: action.payload,
      }
    }
    case Portal.TABLE_VISIT_RETURN_STATE_SET_ISBN: {
      return {
        ...state,
        tableVisitReturnState: {
          ...state.tableVisitReturnState,
          viewedIsbn: action.payload.viewedIsbn,
        },
      }
    }
    case Portal.TABLE_VISIT_RETURN_STATE_CLEAR_ISBN: {
      return {
        ...state,
        tableVisitReturnState: {
          ...state.tableVisitReturnState,
          viewedIsbn: null,
        },
      }
    }
    case Portal.TABLE_VISIT_RETURN_STATE_SET_SORT_AND_FILTER: {
      return {
        ...state,
        tableVisitReturnState: {
          ...state.tableVisitReturnState,
          sortBy: action.payload.sortBy,
          filters: action.payload.filters,
        },
      }
    }
    case Portal.BLUR: {
      return {
        ...state,
        focus: false,
      }
    }
    case Portal.FOCUS: {
      return {
        ...state,
        focus: true,
      }
    }
    default:
      return state
  }
}

export default portalReducer
