import { useState } from 'react'

export const useFieldHandler = (fields) => {
  const [values, setValues] = useState(fields)
  const [fieldsEdited, setFieldEdited] = useState(false)

  const onValueChange = (field, value) => {
    setValues({ ...values, [field]: value })
    setFieldEdited(true)
  }

  return [values, onValueChange, fieldsEdited]
}
