import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Logout from '../components/logout'
import Icon from './icon'
import { isMobileModeSelector, viewSelector } from 'store/selectors'
import { UserType, Views } from '../pseudo-types'

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 60px;
  background-color: ${(p) => p.theme.colors.cream};
  padding-left: ${(p) => (p.isMobile ? '' : '1.5rem')};
  padding-right: ${(p) => (p.isMobile ? '' : '1.5rem')};
  color: ${(p) => p.theme.colors.red};
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.75);
`
export const Constrain = styled.div`
  max-width: ${(p) => p.theme.maxWidths.page};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex: 1 1 25%;
  }
`

const Index = styled(Link)`
  color: ${(p) => p.theme.colors.red};
  font-size: ${(p) => p.theme.fontSize.title};
  line-height: ${(p) => p.theme.fontSize.title};
  text-decoration: none;
  left: ${(p) => p.theme.padding.normal};
`
const Title = styled.div`
  color: ${(p) => p.theme.colors.black};
  font-size: 1.5rem;
  font-weight: 1000;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`

export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.red};
  & > span {
    margin-right: auto;
  }
`

export const RightContainer = styled.div`
  margin-left: ${(p) => p.theme.padding.normal};
  display: inline-flex;
  color: ${(p) => p.theme.colors.red};
  & > span {
    margin-left: auto;
  }
`

export const CenterContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.colors.red};
  font-size: ${(p) => p.theme.fontSize.subtitle};
  text-align: center;
`

// NOTE: slot is used to inject components inside header vie React portals
const HeaderSlot = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Home = styled(Index)`
  margin-right: 25px !important;
  @media only screen and (max-width: 768px) {
    margin-right: 5px !important;
  }
`

const getHomePage = (userType, orgnizationId) => {
  switch (userType) {
    case UserType.ADMIN:
      return '/'
    case UserType.READER:
      return '/reader'
    case UserType.CORRECTIONS:
      return '/corrections'
    case UserType.ORGANIZATION:
      return `/organizations/${orgnizationId}/`
    default:
      return null
  }
}

const Header = ({ userType, isLoggedIn, isMobile, view, children, idsByOrganizationId }) => {
  const { t } = useTranslation()

  const organizationId = Object.keys(idsByOrganizationId).find((id) => id)

  switch (view) {
    case Views.ADMIN_BOOK_LIST: {
      return null
    }
    default:
      break
  }

  const homePage = getHomePage(userType, organizationId)

  return (
    <HeaderContainer isMobile={isMobile}>
      <Constrain>
        <LeftContainer>
          {homePage && (
            <Home to={homePage}>
              <Icon icon="home" />
            </Home>
          )}
          <Title>Lennätin</Title>
          {userType === UserType.ADMIN && (
            <Link style={{ color: 'black', marginLeft: 20 }} to="/booklist">
              {t('bookListing')}
            </Link>
          )}
        </LeftContainer>
      </Constrain>
      <HeaderSlot>{children}</HeaderSlot>
      {isLoggedIn && <Logout isMobile={isMobile} />}
    </HeaderContainer>
  )
}

const mapStateToProps = (state) => ({
  userType: state.user.type,
  isLoggedIn: state.user.state === 'LOGGED_IN',
  isMobile: isMobileModeSelector(state),
  view: viewSelector(state),
  idsByOrganizationId: state.models.books.idsByOrganizationId,
})

export default connect(mapStateToProps)(Header)
