import { DateTime, ProductionTypes } from './common'

export type BookState =
  | 'ORDERED'
  | 'READER_CANDIDATE'
  | 'READER_CONFIRMED'
  | 'READER_BOOKED'
  | 'READING_DONE'
  | 'ON_PLAYER'
  | 'IN_RECORDING'
  | 'IN_VERIFICATION'
  | 'VERIFICATION_DONE'
  | 'DELIVERED'
  | 'BILLED'

export enum BookStateType {
  ON_PLAYER = 'ON_PLAYER',
}

export type BookProductionModel = 'A' | 'B' | 'C' | 'D' | 'OTHER'

export type BookChapter = {
  duration: number
  number: number
}

export type Book = {
  id: string
  isbn: string
  organizationId: string
  organizationName: string
  created: DateTime
  lastUpdated: DateTime
  lastUpdatedByName: string
  workStartDate: string | null
  materialDelivered: boolean
  requestedDelivery: string
  name: string
  hasCoverArt: boolean
  author: string
  narrator: string
  year: string
  state: BookState
  productionReady: boolean
  isBacklist: boolean
  productionModel: BookProductionModel
  readerProgress: number
  readerDuration: number
  readerDeadline: DateTime
  charCount: number
  pageCount: number
  estimatedDuration: number
  corrections: 'SILENCIO' | 'PUBLISHER'
  correctionsBooked: DateTime | null
  chapters: BookChapter[] | null
  celiaRequested: boolean
  celiaStatus: 'NOT_SET' | 'ACCEPTED' | 'REJECTED'
  pronunciationGuideReady: boolean
  productionTypes: ProductionTypes[]
}

export type BookAttachment = {
  url: string
  id: number
  fileName: string
  fileSize: number
  type: 'NORMAL' | 'SPECIAL'
  contentType: string
}
