import { payloadAction } from '../common'
import { apiPUT } from 'src/api'
import { CeliaStatus } from 'src/pseudo-types'
import { actionCreator, withPayload } from '../common'

export const Books = {
  REQUEST: '@@books/REQUEST_BOOKS',
  REQUEST_ALSO_ARCHIVED: '@@books/REQUEST_ALSO_ARCHIVED',
  RECEIVE: '@@books/RECEIVE_BOOKS',
  REFRESH: '@@books/REFRESH',
  CLEAR: '@@book/CLEAR',
}

export const Attachment = {
  DELETE: '@@attachment/REQUEST_DELETE_ATTACHMENT',
  RECEIVE: '@@attachment/RECEIVE_ATTACHEMENT',
  UPDATE: '@@attachment/UPDATED',
  REMOVED: '@@attachment/REMOVED',
}

export const BookActions = {
  ADD_READER_CANDIDATE: '@@book/ADD_READER_CANDIDATE',
  REMOVE_READER_CANDIDATE: '@@book/REMOVE_READER_CANDIDATE',
  FETCH_READER_CANDIDATES: '@@book/REQUEST_READER_CANDIDATES',
  UPDATE_READER_CANDIDATES: '@@book/UPDATE_READER_CANDIDATES',
  UPDATE_READER_CANDIDATE_STATE: '@@book/UPDATE_READER_CANDIDATE_STATE',
  CONFIRM_READER_CANDIDATE: '@@book/CONFIRM_READER_CANDIDATE',
  FETCH_ALL_DATA: '@@book/FETCH_ALL_DATA',
  FETCH_ATTACHMENTS: '@@book/REQUEST_ATTACHMENTS',
  CHANGE_PDF_WIDTH: '@@book/CHANGE_PDF_WIDTH',
  INCREASE_PUBLISHING_PANEL_WIDTH: '@@book/INCREASE_PUBLISHING_PANEL_WIDTH',
  DECREASE_PUBLISHING_PANEL_WIDTH: '@@book/DECREASE_PUBLISHING_PANEL_WIDTH',
  RESET_PUBLISHING_PANEL_WIDTH: '@@book/RESET_PUBLISHING_PANEL_WIDTH',
}

export const BookActionsRequest = {
  ADD_READER_CANDIDATE: 'REQUEST_ADD_READER_CANDIDATE',
  REMOVE_READER_CANDIDATE: 'REQUEST_REMOVE_READER_CANDIDATE',
}

// Action types
export const ORGANIZATION_BOOK_FILTER = 'ORGANIZATION_BOOK_FILTER'
export const ORGANIZATION_SORTING_CHANGED = 'ORGANIZATION_SORTING_CHANGED'
export const ORGANIZATION_BOOK_CREATED = 'ORGANIZATION_BOOK_CREATED'
export const CLEAR_ORGANIZATION_BOOK_CREATED = 'CLEAR_ORGANIZATION_BOOK_CREATED'
export const READER_BOOK_LIST = 'READER_BOOK_LIST'
export const GET_READER_BOOKLIST = 'GET_READER_BOOK_LIST' // TODO: is this used somewhere??
export const RECEIVE_CELIA_BOOKS = 'RECEIVE_CELIA_BOOKS'
export const FETCH_CELIA_BOOKS = 'FETCH_CELIA_BOOKS'
export const FETCH_CORRECTION_BOOKS = 'FETCH_CORRECTION_BOOKS'
export const RECEIVE_CORRECTION_BOOKS = 'RECEIVE_CORRECTION_BOOKS'

// Actions
export const organizationSortingChanged = payloadAction(ORGANIZATION_SORTING_CHANGED)
export const organizationBookCreated = payloadAction(ORGANIZATION_BOOK_CREATED)
export const filterBookRow = payloadAction(ORGANIZATION_BOOK_FILTER)

export const celiaApprove = (book) => {
  return apiPUT(
    `/organizations/${book.original.organizationId}/audiobooks/${book.original.isbn}`,
    { celiaStatus: CeliaStatus.ACCEPTED },
    { headers: { 'If-Unmodified-Since': book.original.lastUpdated } }
  )
}

export const celiaReject = (book) => {
  return apiPUT(
    `/organizations/${book.original.organizationId}/audiobooks/${book.original.isbn}`,
    { celiaStatus: CeliaStatus.REJECTED },
    { headers: { 'If-Unmodified-Since': book.original.lastUpdated } }
  )
}

export const clearCreatedOrganizationBook = () => actionCreator(CLEAR_ORGANIZATION_BOOK_CREATED)

export const requestBooks = () => ({
  type: Books.REQUEST,
})

export const requestAlsoArchivedBooks = () => ({
  type: Books.REQUEST_ALSO_ARCHIVED,
})

export const clearBooks = () => ({
  type: Books.CLEAR,
})

export const fetchReaderBooklist = () => actionCreator(GET_READER_BOOKLIST)
export const fetchCeliaBooklist = () => actionCreator(FETCH_CELIA_BOOKS)
export const fetchCorrectionsBooklist = () => actionCreator(FETCH_CORRECTION_BOOKS)

export const increasePublishingPanelWidthAction = () => actionCreator(BookActions.INCREASE_PUBLISHING_PANEL_WIDTH)
export const decreasePublishingPanelWidthAction = () => actionCreator(BookActions.DECREASE_PUBLISHING_PANEL_WIDTH)
export const resetPublishingPanelWidthAction = () => actionCreator(BookActions.RESET_PUBLISHING_PANEL_WIDTH)

export const fetchBookAttachments = (book) =>
  withPayload(BookActions.FETCH_ATTACHMENTS, { organizationId: book.organizationId, isbn: book.isbn })

export const deleteAttachment = (attachment) => withPayload(Attachment.DELETE, attachment)
export const updatettachment = (attachment) => withPayload(Attachment.RECEIVE, attachment)

export const fetchBookData = (book) => withPayload(BookActions.FETCH_ALL_DATA, { book })
export const fetchReaderCandidatesAction = (book) => withPayload(BookActions.FETCH_READER_CANDIDATES, { book })
export const refreshBooksAndLoadedBook = (book) => withPayload(Books.REFRESH, { book })
