import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { apiPOST } from 'src/api'
import { Button } from './buttons'
import { LOGGED_IN, IDENTIFIED } from 'store/reducers'
import { RedHeading } from 'components'
import { useDispatcher } from '../hooks'

const parseInvitation = () => {
  const matches = /^\/invitation\/([\da-f-]+)$/.exec(window.location.pathname)
  return matches && matches.length > 1 ? matches[1] : ''
}

const Invitation = ({ loggedIn, identified, nonce, name, email }) => {
  const { t } = useTranslation()
  const [invalid, invalidate] = useState(false)
  const { userDispatcher } = useDispatcher()

  const acceptInvite = () => {
    const nonce = parseInvitation()
    if (nonce) {
      return apiPOST(`/invitation/${nonce}`)
        .then(() => (window.location.href = '/'))
        .catch(() => {
          invalidate(true)
        })
    }
  }

  const user = name || email
  if (loggedIn) {
    return null
  } else if (invalid) {
    return (
      <div style={{ marginTop: '100px' }}>
        <RedHeading>{t('invitationIsInvalid')}</RedHeading>
      </div>
    )
  } else if (identified && nonce) {
    return (
      <div style={{ marginTop: '100px' }}>
        <RedHeading>{t('youHaveBeenInvited')}</RedHeading>
        <Button onClick={acceptInvite}>{t('acceptInvitationAs', { user })}</Button>
      </div>
    )
  } else {
    setTimeout(() => {
      userDispatcher.logout()
    }, 5000)
    return <RedHeading>{t('appRequiresInvitation')}</RedHeading>
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.user.name,
    email: state.user.email,
    identified: state.user.state === IDENTIFIED,
    loggedIn: state.user.state === LOGGED_IN,
    nonce: parseInvitation(),
  }
}

export default connect(mapStateToProps)(Invitation)
