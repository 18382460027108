import i18n from 'i18next'
import fi from './locales/fi.json'
import { isDebugEnabled } from './util'

i18n.init({
  lng: 'fi',
  fallbackLng: 'fi',
  resources: { fi },
  // have a common namespace used around the full app
  ns: ['common'],
  defaultNS: 'common',
  debug: isDebugEnabled,
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
})

export default i18n
