import { fetchDistributors, createDistributor, saveDistributor, deleteDistributor } from '.'

export class DistributorDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }

  fetchDistributors = () => this.dispatch(fetchDistributors())
  createDistributor = (distributor) => this.dispatch(createDistributor(distributor))
  saveDistributor = (distributor) => this.dispatch(saveDistributor(distributor))
  deleteDistributor = (distributor) => this.dispatch(deleteDistributor(distributor))
}
