import styled from 'styled-components'

export const Container = styled.div(
  ({ theme }) => `
  padding: ${theme.padding.normal};
`
)

export const PageContainer = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidths.page};
  margin: 0 auto;
`
)

export const Heading = styled.div(
  ({ theme }) => `
  color: ${theme.colors.black};
  font-size: ${theme.fontSize.subtitle};
  text-transform: uppercase;
  font-weight: 600;
  padding: ${(p) => (p.noPadding ? 0 : '0.3rem')};
  user-select: none;
`
)

export const MutedHeading = styled(Heading)(
  ({ theme }) => `
  color: ${theme.colors.creamMuted2};
`
)

export const RedHeading = styled(Heading)(
  ({ theme }) => `
  color: ${theme.colors.red};
  font-size: 0.85em;
`
)

export const Padded = styled.div(
  ({ theme }) => `
  width: 100%;
  margin-bottom: ${theme.padding.normal};
`
)

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(p) => p.justify || 'flex-start'};
`

export const FlexItem = styled.div(
  ({ theme, size }) => `
  flex-grow: ${size ? size : 1};
  flex-basis: ${size ? (size / 2.0) * 100 : 46}%;
  margin-right: ${theme.padding.big};
  @media (max-width: ${theme.breakpoints.mobile}) {
    min-width: 100%;
  }
`
)

export const Column = styled.div`
  background: ${(p) => (p.background ? p.background : 'unset')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`

export const ColumnItem = styled.div(
  ({ theme }) => `
  flex: 1;
  margin-bottom: ${theme.column.breakHeight};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
)

export const ColumnItemNoMargin = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Form = styled.div(
  ({ theme }) => `
  max-width: ${theme.maxWidths.form};
`
)
