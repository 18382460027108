import { PronunciationGuideStatus } from '../../pseudo-types'
import {
  GuideSet,
  PdfViewerState,
  PronunciationCredentials,
  TTSLanguage,
  TTSReaderOptions,
  TTSSettings,
  UserType,
} from '../../typings'

export const pronunciationsSelector = {
  getGuideSet:
    (guideSetType: GuideSet['type']) =>
    (state: any): GuideSet | undefined => {
      return guideSetType === 'DRAFT'
        ? state.models.pronunciations.draftGuideSet
        : state.models.pronunciations.liveGuideSet
    },
  // Enable editing for GUEST user too (coming via link) because
  // link can / will be editable only when made with DRAFT guide set.
  getEditDisabled: (state: any, bookId: string) => {
    const book = state.models.books.byId[bookId]
    return (
      !!state.models.pronunciations.liveGuideSet ||
      [UserType.ADMIN, UserType.ORGANIZATION, UserType.GUEST].includes(state.user.type) === false ||
      (book && book?.pronunciationGuideStatus === PronunciationGuideStatus.NONE)
    )
  },
  isSaving: (state: any): boolean => {
    return state.models.pronunciations.isSaving
  },
  isFetching: (state: any): boolean => {
    return state.models.pronunciations.isFetching
  },
  getTtsSettings: (state: any): TTSSettings => {
    const guideSet: GuideSet = state.models.pronunciations.draftGuideSet
    /**
     * Calculate most used languages
     */
    const mostUsedLanguages = guideSet.items.reduce((acc, cur) => {
      if (cur.language) {
        if (!acc[cur.language]) {
          acc[cur.language] = 1
        } else {
          acc[cur.language] += 1
        }
      }
      return acc
    }, {} as Record<string, number>)

    const allLangs = [...state.models.pronunciations.ttsSettings.languages]

    /**
     * Add count field for all languages
     */
    allLangs.forEach((item, idx) => {
      if (!mostUsedLanguages[item.languageCode]) {
        allLangs[idx].count = 0
        return
      }
      allLangs[idx].count = mostUsedLanguages[item.languageCode]
    })

    return {
      ...state.models.pronunciations.ttsSettings,
      languages: allLangs,
    }
  },
  getTtsReaderOptions: (state: any): TTSReaderOptions => {
    return state.models.pronunciations.ttsReaderOptions
  },
  getTextContent: (state: any): string[] => {
    return state.models.pronunciations.textContent
  },
  getViewerState: (state: any): PdfViewerState => {
    return state.models.pronunciations.viewer
  },
  getCredentials: (state: any): PronunciationCredentials => {
    return state.models.pronunciations.credentials
  },
  getCommentBlur: (state: any): boolean => {
    return state.models.pronunciations.blurCommentField
  },
  getCommentSave: (state: any): boolean => {
    return state.models.pronunciations.shouldSave
  },
  getDraftItems: (state: any): any => {
    return state.models.pronunciations.draftGuideSet.items
  },
  getCopyDraftItems: (state: any): any => {
    return state.models.pronunciations.guideSetCopy.items
  },
  getIsFocused: (state: any): boolean => {
    return state.models.pronunciations.isFocused
  },
  getSaveTime: (state: any): number => {
    return state.models.pronunciations.saveTime
  },
  getCommonInfo: (state: any): any => {
    const { bookDetails, generalInstructions } = state.models.pronunciations.draftGuideSet
    return { bookDetails, generalInstructions }
  },
  getTtsGengerVoices: (state: any): TTSLanguage[] => {
    return state.models.pronunciations.ttsSettings.languages
  },
}
