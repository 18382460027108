import {
  GuideSet,
  PdfViewerState,
  PronunciationCredentials,
  TTSReaderOptions,
  TTSSettings,
  PronunciationItem,
} from '../../typings'
import { generateId } from '../../utils'
import {
  PronunciationsAddItemAction,
  PronunciationsDeleteItemAction,
  PronunciationsDeleteSelectedItemsAction,
  PronunciationsEditItemAction,
  PronunciationsFetchAction,
  PronunciationsFetchSuccessAction,
  PronunciationsFetchTextToSpeechSuccessAction,
  PronunciationsPublishSuccessAction,
  PronunciationsResetGuideSet,
  PronunciationsSelectItemsAction,
  PronunciationsSetCredentialsAction,
  PronunciationsSetSaving,
  PronunciationsSetTxtContentAction,
  PronunciationsSetViewerStateAction,
  PronunciationsUpdateAction,
  PronunciationsUpdateSuccessAction,
  PronunciationsUpdateTextToSpeechReaderOptionsAction,
  PronunciationsSetGuideSetCopy,
  PronunciationsResetCommonInfo,
  PronunciationsSetOldSnapshot,
  PronunciationsSetBlur,
  PronunciationsCommonInfoAction,
  PRONUNCIATIONS_ADD_ITEM,
  PRONUNCIATIONS_DELETE_ITEM,
  PRONUNCIATIONS_DELETE_SELECTED_ITEMS,
  PRONUNCIATIONS_EDIT_ITEM,
  PRONUNCIATIONS_FETCH,
  PRONUNCIATIONS_FETCH_SUCCESS,
  PRONUNCIATIONS_FETCH_TTS_SUCCESS,
  PRONUNCIATIONS_PUBLISH_SUCCESS,
  PRONUNCIATIONS_RESET_GUIDE_SET,
  PRONUNCIATIONS_SAVE,
  PRONUNCIATIONS_SELECT_ITEMS,
  PRONUNCIATIONS_SET_CREDENTIALS,
  PRONUNCIATIONS_SET_TXT_CONTENT,
  PRONUNCIATIONS_SET_VIEWER_STATE,
  PRONUNCIATIONS_UPDATE,
  PRONUNCIATIONS_UPDATE_SUCCESS,
  PRONUNCIATIONS_UPDATE_TTS_READER_OPTIONS,
  PRONUNCIATIONS_SET_COPY,
  PRONUNCIATIONS_RESET_COMMON_INFO,
  PRONUNCIATIONS_SET_ITEMS,
  PRONUNCIATIONS_SET_FOCUS,
  PRONUNCIATIONS_SET_COMMON_INFO,
} from '../actions'

export type PronunciationsState = {
  isSaving: boolean
  isFetching: boolean
  viewer: PdfViewerState
  ttsSettings: TTSSettings
  credentials?: PronunciationCredentials
  ttsReaderOptions: TTSReaderOptions
  textContent: string[]
  draftGuideSet: GuideSet
  liveGuideSet?: GuideSet
  blurCommentField: boolean
  shouldSave: boolean
  guideSetCopy: GuideSet
  isFocused: boolean
  saveTime?: number
}

const initialState: PronunciationsState = {
  isSaving: false,
  isFetching: false,
  blurCommentField: false,
  shouldSave: false,
  textContent: [],
  ttsSettings: {
    languages: [],
  },
  isFocused: false,
  credentials: undefined,
  viewer: {
    currentPage: 1,
    pageCount: 0,
  },
  ttsReaderOptions: {
    gender: 'FEMALE',
    speakingRate: 1,
  },
  /**
   * Keep default structure for draft guide set. Backend
   * will return empty guidesets at first load.
   */
  draftGuideSet: {
    type: 'DRAFT',
    bookDetails: '',
    correctionsMarkers: [],
    generalInstructions: '',
    items: [],
    lastUpdated: new Date().toISOString(),
  },
  guideSetCopy: {
    type: 'DRAFT',
    bookDetails: '',
    correctionsMarkers: [],
    generalInstructions: '',
    items: [],
    lastUpdated: new Date().toISOString(),
  },
}

type GuideSetsByType = Record<GuideSet['type'], GuideSet | undefined>

const mapGuideSetsByType = (guideSets: GuideSet[]): GuideSetsByType => {
  return guideSets.reduce((obj, guideSet) => {
    obj[guideSet.type] = guideSet
    return obj
  }, {} as GuideSetsByType)
}

type PronunciationsAction =
  | PronunciationsFetchSuccessAction
  | PronunciationsUpdateSuccessAction
  | PronunciationsUpdateAction
  | PronunciationsFetchAction
  | PronunciationsAddItemAction
  | PronunciationsDeleteItemAction
  | PronunciationsEditItemAction
  | PronunciationsSelectItemsAction
  | PronunciationsFetchTextToSpeechSuccessAction
  | PronunciationsUpdateTextToSpeechReaderOptionsAction
  | PronunciationsDeleteSelectedItemsAction
  | PronunciationsSetTxtContentAction
  | PronunciationsSetViewerStateAction
  | PronunciationsPublishSuccessAction
  | PronunciationsSetCredentialsAction
  | PronunciationsResetGuideSet
  | PronunciationsSetSaving
  | PronunciationsSetGuideSetCopy
  | PronunciationsResetCommonInfo
  | PronunciationsSetOldSnapshot
  | PronunciationsSetBlur
  | PronunciationsCommonInfoAction

const pronunciationsReducer = (state = initialState, action: PronunciationsAction): PronunciationsState => {
  switch (action.type) {
    /**
     * FETCH
     */

    case PRONUNCIATIONS_FETCH: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case PRONUNCIATIONS_SET_COPY: {
      const { guideSets } = action.payload
      return {
        ...state,
        guideSetCopy: guideSets[0],
      }
    }

    case PRONUNCIATIONS_FETCH_SUCCESS: {
      const { guideSets } = action.payload
      const guideSetsByType = mapGuideSetsByType(guideSets)
      return {
        ...state,
        isFetching: false,
        draftGuideSet: guideSetsByType.DRAFT ?? state.draftGuideSet,
        liveGuideSet: guideSetsByType.LIVE,
      }
    }

    case PRONUNCIATIONS_RESET_COMMON_INFO: {
      const { bookDetails, generalInstructions } = state.guideSetCopy
      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          bookDetails: bookDetails,
          generalInstructions: generalInstructions,
        },
      }
    }

    case PRONUNCIATIONS_RESET_GUIDE_SET: {
      return {
        ...state,
        isFetching: false,
        draftGuideSet: {
          ...state.draftGuideSet,
          items: initialState.draftGuideSet.items,
        },
        liveGuideSet: initialState.liveGuideSet,
        saveTime: undefined,
      }
    }

    /**
     * UPDATE
     */

    case PRONUNCIATIONS_UPDATE: {
      return {
        ...state,
        isSaving: true,
      }
    }

    case PRONUNCIATIONS_UPDATE_SUCCESS: {
      const { guideSet } = action.payload
      const { draftGuideSet } = state
      return {
        ...state,
        isSaving: false,
        draftGuideSet: {
          ...guideSet,
          items: guideSet.items.map((item: PronunciationItem, idx: number) => {
            if (draftGuideSet.items[idx].selected) {
              item.selected = true
              return item
            }
            return item
          }),
        },
      }
    }

    /**
     * ITEMS
     */

    case PRONUNCIATIONS_ADD_ITEM: {
      const { payload: item, wordPos } = action
      const { items } = state.draftGuideSet

      // Add new item to correct position
      items.splice(wordPos, 0, { ...item, id: generateId() })

      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          items,
        },
      }
    }

    case PRONUNCIATIONS_EDIT_ITEM: {
      const { payload, id } = action

      /**
       * If multiple items selected, we need
       * to sync other selected items to have save
       * language as the first selected one.
       */
      const newItems = state.draftGuideSet.items.map((item) => {
        /**
         * Check if we need to copy item data to
         * other selected ones.
         *
         * Check that:
         * - first selected item exists
         * - the edited item is first selected item
         * - current item is selected
         */
        if (item.selected && item.id !== id) {
          return {
            ...item,
            language: payload.language ?? item.language,
          }
        }

        if (item.id === id) {
          return {
            ...item,
            ...payload,
          }
        }

        return item
      })

      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          items: newItems,
        },
      }
    }

    case PRONUNCIATIONS_DELETE_ITEM: {
      const { id } = action
      const newItems = state.draftGuideSet.items.filter((val) => val.id !== id)
      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          items: newItems,
        },
      }
    }

    case PRONUNCIATIONS_SELECT_ITEMS: {
      const { itemIds, isSelected } = action
      const newItems = state.draftGuideSet.items.map((val) => {
        const included = itemIds.includes(val.id)
        const itemSelected = included ? isSelected : val.selected
        return {
          ...val,
          selected: itemSelected,
        }
      })
      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          items: newItems,
        },
      }
    }

    case PRONUNCIATIONS_DELETE_SELECTED_ITEMS: {
      const newItems = state.draftGuideSet.items.filter((val) => val.selected !== true)
      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          items: newItems,
        },
      }
    }

    // TEXT TO SPEECH

    case PRONUNCIATIONS_FETCH_TTS_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        ttsSettings: payload,
      }
    }

    case PRONUNCIATIONS_UPDATE_TTS_READER_OPTIONS: {
      const { payload } = action

      return {
        ...state,
        ttsReaderOptions: {
          ...state.ttsReaderOptions,
          ...payload,
        },
      }
    }

    case PRONUNCIATIONS_SET_CREDENTIALS: {
      const { id, editable } = action.payload
      return {
        ...state,
        credentials: {
          credentialId: id,
          editable,
        },
      }
    }

    // MISC

    case PRONUNCIATIONS_PUBLISH_SUCCESS: {
      const { guideSet } = action.payload
      /**
       * Publish success returns only live guide set
       */
      return {
        ...state,
        isFetching: false,
        liveGuideSet: guideSet,
      }
    }

    case PRONUNCIATIONS_SET_TXT_CONTENT: {
      const { textContent } = action
      return {
        ...state,
        textContent,
      }
    }

    case PRONUNCIATIONS_SAVE: {
      const { shouldSave } = action.payload
      return {
        ...state,
        shouldSave: shouldSave,
        saveTime: Date.now(),
      }
    }

    case PRONUNCIATIONS_SET_VIEWER_STATE: {
      const { payload } = action
      return {
        ...state,
        viewer: {
          ...state.viewer,
          ...payload,
        },
      }
    }

    case PRONUNCIATIONS_SET_ITEMS: {
      const { items } = action
      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          items,
        },
      }
    }

    case PRONUNCIATIONS_SET_FOCUS: {
      return {
        ...state,
        isFocused: action.isFocused,
      }
    }

    case PRONUNCIATIONS_SET_COMMON_INFO: {
      const { bookDetails, generalInstructions } = action
      return {
        ...state,
        draftGuideSet: {
          ...state.draftGuideSet,
          bookDetails,
          generalInstructions,
        },
      }
    }

    default:
      return state
  }
}

export default pronunciationsReducer
