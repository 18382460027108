export const getNewDistribution = (state) => {
  return state.models.distributions.creating
}

export const getDistributionIds = (isbn) => (state) => {
  return state.models.distributions.idsByBookId[isbn] || []
}

export const getDistributions = (isbn) => (state) => {
  return getDistributionIds(isbn)(state)
    .map((id) => state.models.distributions.byId[id])
    .filter((distribution) => !!distribution)
    .map((distribution) => {
      return {
        ...distribution,
      }
    })
}
