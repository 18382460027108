import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LOGGED_IN } from 'store/reducers'
import { Padded } from 'components'

const InvitationPage = ({ loggedIn }) => {
  const { t } = useTranslation()
  if (loggedIn) {
    return (
      <div style={{ marginTop: '60px' }}>
        <h1>{t('cannotUseInvitationWithExistingUser')}</h1>
        <Padded>{t('logoutOrCreateNewAccount')}</Padded>
        <Padded>
          <Link to="/">{t('continueWithCurrentUser')}</Link>
        </Padded>
      </div>
    )
  }
  return null
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.user.state === LOGGED_IN,
  }
}

export default connect(mapStateToProps)(InvitationPage)
