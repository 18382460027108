import {
  setMobileMode,
  sendMessage,
  clearMessages,
  clearErrors,
  setView,
  clearView,
  setTableVisitReturnStateIsbn,
  clearTableVisitReturnStateIsbn,
  setTableVisitReturnStateSortAndFilter,
  setPortalIsLoading,
  setEditableBook,
  sortAdminTable,
  onPortalBlur,
  onPortalFocus,
  setError409,
} from '.'

export class PortalDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }
  setPortalIsLoading = (value) => this.dispatch(setPortalIsLoading(value))
  setMobileMode = (value) => this.dispatch(setMobileMode(value))
  sendMessage = (message) => this.dispatch(sendMessage(message))
  clearMessages = () => this.dispatch(clearMessages())
  clearErrors = () => this.dispatch(clearErrors())
  setView = (view) => this.dispatch(setView(view))
  clearView = () => this.dispatch(clearView())
  setTableVisitReturnStateIsbn = (viewedIsbn) => this.dispatch(setTableVisitReturnStateIsbn(viewedIsbn))
  clearTableVisitReturnStateIsbn = () => this.dispatch(clearTableVisitReturnStateIsbn())
  setTableVisitReturnStateSortAndFilter = (sortBy, filters) =>
    this.dispatch(setTableVisitReturnStateSortAndFilter(sortBy, filters))
  setEditableBook = (book) => this.dispatch(setEditableBook(book))
  sortAdminTable = (sortData) => this.dispatch(sortAdminTable(sortData))
  onBlur = () => this.dispatch(onPortalBlur())
  onFocus = () => this.dispatch(onPortalFocus())
  setError409 = (message) => this.dispatch(setError409(message))
}
