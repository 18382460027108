import { useDispatch } from 'react-redux'
import {
  addPronunciationItem,
  deletePronunciationItem,
  deleteSelectedPronunciationItems,
  editPronunciationItem,
  fetchPronunciations,
  fetchTextToSpeechSettings,
  publishPronunciations,
  resetGuideSet,
  selectPronunciationItems,
  setPdfViewerState,
  setPronunciationsCredentials,
  setPronunciationsStale,
  setPronunciationsTextContent,
  setSaving,
  updatePronunciations,
  updateTtsReaderOptions,
  resetCommonInfo,
  setGuideItems,
  setFocus,
  PronunciationsSetCommonInfoPayload,
  setCommonInfo,
} from '.'

import {
  PdfViewerState,
  PronunciationsAddItemActionPayload,
  PronunciationsDeleteItemActionPayload,
  PronunciationsEditItemActionPayload,
  PronunciationsFetchActionPayload,
  PronunciationsPublishActionPayload,
  PronunciationsSelectItemsActionPayload,
  PronunciationsSetCredentialsActionPayload,
  PronunciationsSetTxtContentActionPayload,
  PronunciationsUpdateActionPayload,
  TTSReaderOptions,
  PronunciationItem,
} from '../../../typings'

type Dispatch = ReturnType<typeof useDispatch>

export class PronunciationsDispatcher {
  dispatch: Dispatch

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  fetchPronunciations = (payload: PronunciationsFetchActionPayload) => this.dispatch(fetchPronunciations(payload))
  updatePronunciations = (payload: PronunciationsUpdateActionPayload) => this.dispatch(updatePronunciations(payload))
  addItem = (payload: PronunciationsAddItemActionPayload) => this.dispatch(addPronunciationItem(payload))
  editItem = (payload: PronunciationsEditItemActionPayload) => this.dispatch(editPronunciationItem(payload))
  deleteItem = (payload: PronunciationsDeleteItemActionPayload) => this.dispatch(deletePronunciationItem(payload))
  deleteSelectedItems = () => this.dispatch(deleteSelectedPronunciationItems())
  selectItems = (payload: PronunciationsSelectItemsActionPayload) => this.dispatch(selectPronunciationItems(payload))
  publish = (payload: PronunciationsPublishActionPayload) => this.dispatch(publishPronunciations(payload))
  fetchTtsSettings = () => this.dispatch(fetchTextToSpeechSettings())
  updateReaderOptions = (payload: Partial<TTSReaderOptions>) => this.dispatch(updateTtsReaderOptions(payload))
  setStale = (isStale: boolean) => this.dispatch(setPronunciationsStale(isStale))
  setSaving = (shouldSave: boolean) => this.dispatch(setSaving(shouldSave))
  setTextContent = (payload: PronunciationsSetTxtContentActionPayload) =>
    this.dispatch(setPronunciationsTextContent(payload))
  setViewerState = (payload: Partial<PdfViewerState>) => this.dispatch(setPdfViewerState(payload))
  resetGuideSet = () => this.dispatch(resetGuideSet())
  setCredentials = (payload: PronunciationsSetCredentialsActionPayload) =>
    this.dispatch(setPronunciationsCredentials(payload))
  resetCommonInfo = () => this.dispatch(resetCommonInfo())
  setGuideItems = (items: PronunciationItem[]) => this.dispatch(setGuideItems(items))
  setFocus = (isFocused: boolean) => this.dispatch(setFocus(isFocused))
  setCommonInfo = (payload: PronunciationsSetCommonInfoPayload) => this.dispatch(setCommonInfo(payload))
}
