import { useEffect, useRef } from 'react'
import { useDispatcher } from '../../../hooks/useDispatcher'

// Default interval value is 5 minutes
export const useAutoSave = (intervalValue: number = 5 * 60 * 1000) => {
  const { pronunciationsDispatcher } = useDispatcher()

  const internalRef = useRef<ReturnType<typeof setInterval>>()

  const clearAutosaveInterval = () => internalRef.current && clearInterval(internalRef.current)

  useEffect(() => {
    return () => {
      clearAutosaveInterval()
    }
  }, [])

  const startInterval = (enabled: boolean) => {
    if (enabled && !internalRef.current) {
      internalRef.current = setInterval(() => {
        pronunciationsDispatcher.setSaving(true)
      }, intervalValue)
    }
  }

  /**
   * Start or cancel auto save
   * @param enabled boolean
   */
  const setAutosaveEnabled = (enabled: boolean) => {
    startInterval(enabled)
  }

  return { setAutosaveEnabled }
}
