import { updateWithId } from './common'
import { UPDATE_AUDIO, PLAY_AUDIO } from 'store/actions'

const initialState = {
  byId: {},
  playingId: null,
}

const audioReducer = (state = initialState, action) => {
  let values = action.payload

  switch (action.type) {
    case UPDATE_AUDIO:
      return {
        ...state,
        byId: updateWithId(state.byId, values),
      }

    case PLAY_AUDIO:
      return {
        ...state,
        playingId: values.id,
      }

    default:
      return state
  }
}

export default audioReducer
