import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ApiErrorToast from 'components/ToastifyNotifications/ApiErrorToast'
import HttpErrorToast from 'components/ToastifyNotifications/HttpErrorToast'
import SuccessToast from 'components/ToastifyNotifications/SuccessToast'
import { messageSelector, errorSelector } from 'store/selectors'
import { useDispatcher } from '../hooks'

/**
 * Handles the display of notifications when certain things happen (stuff is saved to Redux store). Currently ToastLayer reacts to the changes of portal messages and portal errors.
 *
 * @returns {ReactElement} ToastLayer - A notification popup
 */
export const ToastLayer = () => {
  const { t } = useTranslation()

  const portalMessage = useSelector(messageSelector)
  const portalErrors = useSelector(errorSelector)

  const { portalDispatcher } = useDispatcher()

  useEffect(
    () => () => {
      portalDispatcher.clearMessages()
    },
    []
  )

  // On default (when initialized or when errors are cleared) portalErrors is an empty object
  const errorCount = Object.keys(portalErrors).length

  if (errorCount) {
    if (Object.prototype.hasOwnProperty.call(portalErrors, 'error409')) {
      return <>{toast(<HttpErrorToast callingFunction={portalMessage.callingFunction} />, { autoClose: 5000 })}</>
    } else if (Object.prototype.hasOwnProperty.call(portalErrors, 'general')) {
      return <>{toast(<ApiErrorToast error={portalErrors.general} />, { autoClose: 5000 })}</>
    }
  } else {
    if (portalMessage.length > 0) {
      return (
        <>
          {toast(<SuccessToast title={t('messages.note')} text={portalMessage} />, {
            onClose: () => portalDispatcher.clearMessages(),
            autoClose: 5000,
          })}
        </>
      )
    } else {
      // When there's no errors or messages, the toast-layer shall not be shown
      return null
    }
  }
}
