import { useRef } from 'react'

export const useTimer = () => {
  const timerState = useRef(null)

  const finish = () => {
    if (timerState.current) {
      timerState.current.onComplete()
    }
    stop()
  }

  const stop = () => {
    if (timerState.current) {
      clearTimeout(timerState.current.timerID)
      timerState.current = null
    }
  }

  const start = (timeout, onComplete) => {
    stop()
    timerState.current = {
      timerID: setTimeout(finish, timeout),
      onComplete: onComplete,
    }
  }

  return [start, finish, stop]
}
