import { login, firebaseLogin, logout, fetchUsers, deleteUser, modifyUser, createExternalUser } from '.'

export class UserDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }

  login = (userObj) => this.dispatch(login(userObj))
  firebaseLogin = (payload) => this.dispatch(firebaseLogin(payload))
  logout = () => this.dispatch(logout())
  fetchUsers = (userType) => this.dispatch(fetchUsers(userType))
  deleteUser = (userId) => this.dispatch(deleteUser(userId))
  modifyUser = (userId, name, blocked, hourlySalary, productionTypes) =>
    this.dispatch(modifyUser(userId, name, blocked, hourlySalary, productionTypes))
  createExternalUser = ({ name, email, productionType }) =>
    this.dispatch(createExternalUser(name, email, productionType)) // createExternalUser(name, email)
}
