import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FaDoorOpen } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import { LOGGED_OUT } from 'store/reducers'
import { useDispatcher } from '../hooks'

const LogoutIcon = styled(FaDoorOpen)`
  font-size: 2rem;
  color: #262626;
`

const LoginName = styled.div`
  display: inline-flex;
  color: ${(p) => p.theme.colors.grayBackground};
  font-size: ${(p) => p.theme.fontSize.normal};
  padding-right: 0.5em;
`

const Button = styled.div`
  text-transform: uppercase;
  cursor: pointer;
  color: ${(p) => p.theme.colors.black};
  font-size: ${(p) => p.theme.fontSize.logout};
  line-height: ${(p) => p.theme.fontSize.logout};
`

const NameField = styled.div`
  max-width: 128px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-right: 5px; */
  justify-content: space-evenly;
  @media only screen and (max-width: 768px) {
    flex: 1 1 25%;
  }
`

const decodeHtml = (html) => {
  var txt = document.createElement('textarea')
  txt.innerHTML = html

  return txt.value
}

const Logout = ({ userName, userEmail, loggedOut, isMobile }) => {
  const { userDispatcher } = useDispatcher()
  const history = useHistory()
  const [showLogOutBtn, setShowLogOutBtn] = useState(true)

  useEffect(() => {
    if (history.location.pathname.includes('pronunciation-guide')) {
      setShowLogOutBtn(false)
    } else {
      setShowLogOutBtn(true)
    }
  }, [history.location.pathname])

  if (loggedOut) {
    return null
  }
  const { t } = useTranslation()
  // console.log(decodeURIComponent(userName))

  const handleLogOut = () => {
    userDispatcher.logout()
    history.push({ pathname: '/' })
  }

  return (
    <Container>
      <LoginName title={userEmail && userEmail}>
        <NameField>{userName}</NameField>
        {!isMobile && <div style={{ marginLeft: '5px' }}>|</div>}
      </LoginName>
      {isMobile ? (
        <LogoutIcon onClick={handleLogOut} />
      ) : (
        showLogOutBtn && (
          <Button onClick={handleLogOut}>
            <span style={{ fontSize: '0.85rem' }}>{t('logOut')}</span>
          </Button>
        )
      )}
    </Container>
  )
}

const mapStateToProps = (state) => ({
  userName: decodeHtml(state.user.name || state.user.email),
  userEmail: decodeHtml(state.user.email),
  loggedOut: state.user.state === LOGGED_OUT,
})

export default connect(mapStateToProps)(Logout)
