import { withPayload, actionCreator } from '../common'

// Action types processed in reducer
export const LOGIN = '@@user/LOGIN'
export const LOGOUT = '@@user/LOGOUT'
export const FIREBASE_LOGIN = '@@user/FIREBASE_LOGIN'
export const UPDATE_USERS = '@@users/RECEIVE'
export const REMOVE_USER = '@@user/REMOVE'
export const WRITE_USER = '@@user/WRITE'

export const Users = {
  // processed in saga
  FETCH: '@@users/FETCH',
  DELETE: '@@user/DELETE',
  MODIFY: '@@user/MODIFY',
  INVITE_EXTERNAL: '@@users/CREATE_EXTERNAL_USER',
  LOGOUT: '@@users/LOGOUT',
}

export const logout = () => actionCreator(Users.LOGOUT)
export const login = (userObj) => withPayload(LOGIN, userObj)
export const firebaseLogin = (payload) => withPayload(FIREBASE_LOGIN, payload)
export const fetchUsers = (userType) => withPayload(Users.FETCH, { userType })
export const deleteUser = (userId) => withPayload(Users.DELETE, { userId })
export const modifyUser = (userId, name, blocked, hourlySalary, productionTypes) =>
  withPayload(Users.MODIFY, { userId, name, blocked, hourlySalary, productionTypes })
export const createExternalUser = (name, email, productionType) =>
  withPayload(Users.INVITE_EXTERNAL, { name, email, productionType })
