import { createSelector } from 'reselect'

export const isMobileModeSelector = (state) => state.portal.isMobile

export const isLoadingSelector = (state) => state.portal.loading

export const loadedBooksSelector = (state) => state.portal.loadedBooks

export const dirtyBookSelector = (state) => state.portal.adminView.dirtyBook

export const isLoadingBookSelector = (bookId) => (state) =>
  state.portal.loadingBooks.findIndex((id) => id === bookId) !== -1

export const hasBookLoadedSelector = (isbn) =>
  createSelector([loadedBooksSelector], (loadedBooks) => new Set(loadedBooks).has(isbn))

export const messageSelector = (state) => state.portal.message

export const errorSelector = (state) => state.portal.errors

export const viewSelector = (state) => state.portal.currentView

export const tableVisitReturnState = (state) => state.portal.tableVisitReturnState

export const getAdminTableSort = (state) => state.portal.adminView.sort

export const isPageInFocus = (state) => state.portal.focus
