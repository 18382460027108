import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import ApiErrorToast from 'components/ToastifyNotifications/ApiErrorToast'

const CancelToken = axios.CancelToken
export const tokenSource = CancelToken.source()

class RestAPI {
  setToken(idToken) {
    this.axios = axios.create({
      baseURL: '/api',
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    })
    return this
  }
  getHeaders() {
    const { Authorization } = this.axios.defaults.headers
    return { Authorization }
  }
  get(url, data, config, showToast) {
    return this.axios
      .get(url, { params: data, ...config })
      .then((response) => response.data)
      .catch((error) => onError(error, showToast))
  }
  post(url, data, config, showToast) {
    return this.axios
      .post(url, data, config)
      .then((response) => response.data)
      .catch((error) => onError(error, showToast))
  }
  put(url, data, config, showToast) {
    return this.axios
      .put(url, data, config)
      .then((response) => response.data)
      .catch((error) => onError(error, showToast))
  }
  delete(url, data, config, showToast) {
    return this.axios
      .delete(url, { params: data, ...config })
      .then((response) => response.data)
      .catch((error) => onError(error, showToast))
  }
}

const onError = (error, showToast) => {
  if (axios.isCancel(error)) {
    console.log('cancelled')
  }
  return notifyErrorResponse(error, showToast)
}

const notifyErrorResponse = (error, showToast) => {
  // error is expected -> no notification toast
  if (showToast) {
    toast(<ApiErrorToast error={error} />, ApiErrorToast.options)
  }
  return Promise.reject(error)
}

export const api = new RestAPI().setToken()
export const getHeaders = () => api.getHeaders()

export const apiGET = (url, data = {}, config = {}, showToast = true) => api.get(url, data, config, showToast)

export const apiPOST = (url, data = {}, config = {}, showToast = true) => api.post(url, data, config, showToast)

export const apiPUT = (url, data = {}, config = {}, showToast = true) => api.put(url, data, config, showToast)

export const apiDELETE = (url, data = {}, config = {}, showToast = true) => api.delete(url, data, config, showToast)
