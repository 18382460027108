import { updateWithId, updateManyWithId } from './common'
import { Distributor, HYDRATE_DISTRIBUTORS } from 'store/actions'

const initialState = {
  creating: null,
  byId: {},
}

const distributorsReducer = (state = initialState, action) => {
  const payload = action.payload

  switch (action.type) {
    case HYDRATE_DISTRIBUTORS:
      return {
        ...state,
        byId: updateManyWithId(state.byId, payload),
      }
    case Distributor.TOGGLE_DISTRIBUTOR_CREATION:
      if (!state.creating) {
        return {
          ...state,
          creating: {
            name: '',
            type: 'OTHER',
            format: 'mp3',
            bitrate: '80',
            doubleStereoBitrate: false,
            emails: '',
            ftpScheme: 'NO_FTP',
            ftpAddress: '',
            ftpPort: '',
            ftpUsername: '',
            ftpPassword: '',
            ftpPath: '',
            creating: true,
          },
        }
      }
      return {
        ...state,
        creating: null,
      }

    case Distributor.UPDATE_DISTRIBUTOR:
      if (payload.creating === true) {
        return {
          ...state,
          creating: payload,
        }
      }

      return {
        ...state,
        byId: updateWithId(state.byId, payload),
      }

    case Distributor.REMOVE_DISTRIBUTOR: {
      const id = payload
      //removing key from object by using object deconstruct
      const {
        // eslint-disable-next-line no-unused-vars
        byId: { [id]: keyToBeRemove, ...restOfKeys },
      } = state // eslint-disable-line
      return { ...state, byId: restOfKeys }
    }

    default:
      return state
  }
}

export default distributorsReducer
