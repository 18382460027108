import { all, call, put, takeLatest } from 'redux-saga/effects'
import { apiPOST } from 'src/api'
import { pick } from 'src/util'
import { START_INVITATION_CREATION, UPDATE_INVITATION, TOGGLE_INVITATION_CREATION } from 'store/actions'
import { ProductionTypes } from '../../typings'

function* createInvitation(action) {
  const invitation = action.payload
  const invitationRecord = pick(invitation, ['email', 'admin', 'organizationId', 'userType', 'productionTypes'])

  if (invitationRecord.productionTypes) {
    if (invitationRecord.productionTypes[0] === ProductionTypes.SILENCIO_AANINEN) {
      invitationRecord.productionTypes = [ProductionTypes.SILENCIO, ProductionTypes.AANINEN]
    }
  }

  let newInvitation
  try {
    newInvitation = yield call(apiPOST, `/invitation`, invitationRecord)
    yield put({ type: TOGGLE_INVITATION_CREATION })
  } catch (e) {
    const message = e.response ? e.response.data.message : e.message
    console.error(message)
    // errorAction({ form: 'invitation', message: message })
    console.error(e)
  } finally {
    yield all([put({ type: UPDATE_INVITATION, payload: newInvitation }), put({ type: TOGGLE_INVITATION_CREATION })])
  }
}

function* invitationSaga() {
  yield takeLatest(START_INVITATION_CREATION, createInvitation)
}

export default invitationSaga
