import { all, take, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { apiGET, apiPUT, apiPOST, apiDELETE } from 'src/api'
import {
  Books,
  BookActions,
  BookActionsRequest,
  CorrectionsListenerActions,
  RECEIVE_CELIA_BOOKS,
  FETCH_CELIA_BOOKS,
  FETCH_CORRECTION_BOOKS,
  RECEIVE_CORRECTION_BOOKS,
  Portal,
  Attachment,
  requestBooks,
  fetchReaderCandidatesAction,
  setPortalIsLoading,
  Reader,
  fetchBook,
  fetchOrganizationBookDistributions,
  fetchSingleOrganization,
} from 'store/actions'
import { CorrectionsStatus } from 'src/pseudo-types'
import { pick } from 'src/util'

function* refreshBooks(action) {
  const { book } = action.payload

  yield put(requestBooks())
  yield take(Books.RECEIVE)
  yield put(fetchReaderCandidatesAction(book))
  yield put(fetchBook(book.organizationId, book.isbn))
}

// When a correction listener claims a book for him- / herself
function* claimCorrectionsBook(action) {
  const { organizationId, isbn, lastUpdated, userId } = action.payload

  const networkPayload = {
    newStatus: CorrectionsStatus.CLAIMED,
  }

  if (userId) {
    networkPayload.correctionsUserId = userId
  }

  try {
    yield call(apiPUT, `/organizations/${organizationId}/audiobooks/${isbn}/corrections`, networkPayload, {
      headers: { 'If-Unmodified-Since': lastUpdated },
    })

    if (userId) {
      // userId means admin is doing the claiming for a user, no need to fetch list then
      yield put(fetchBook(organizationId, isbn))
    } else {
      yield fetchCorrectionsBookList()
    }
  } catch (error) {
    const { response } = error

    if (response.status === 409) {
      yield put({ type: Portal.ERROR_409, payload: { callingFunction: 'claimCorrectionsBook' } })
    } else {
      const message = response ? response.data.message : error.message
      yield put({ type: Portal.ERROR_GENERAL, payload: message })
    }
  }
}

function* verifyListenComplete(action) {
  const { organizationId, isbn, lastUpdated, comments, correctionsDuration } = action.payload

  try {
    yield call(
      apiPUT,
      `/organizations/${organizationId}/audiobooks/${isbn}/corrections`,
      {
        newStatus: CorrectionsStatus.COMPLETED,
        listenerComments: comments,
        correctionsDuration,
      },
      { headers: { 'If-Unmodified-Since': lastUpdated } }
    )
    yield fetchCorrectionsBookList()
  } catch (error) {
    const { response } = error

    console.error('> Failed to update the status of an audiobook', error, response)
    // TODO: Use only one common type for HTTP errors and pass a whole error type object
    if (response.status === 409) {
      yield put({ type: Portal.ERROR_409, payload: { callingFunction: 'verifyListenComplete' } })
    } else {
      const message = response ? response.data.message : error.message
      yield put({ type: Portal.ERROR_GENERAL, payload: message })
    }
  }
}

// Fetch all books except the archived
function* fetchAllBooks() {
  try {
    yield put(setPortalIsLoading(true))
    const { audiobooks } = yield call(apiGET, `/audiobooks`)
    yield put({ type: Books.RECEIVE, payload: audiobooks })
    yield put({ type: Portal.CLEAR_LOADED_BOOKS })
    yield put(setPortalIsLoading(false))
  } catch (e) {
    console.error(e)
  }
}

// Fetch all books, also the archived
function* fetchAlsoArchived() {
  try {
    yield put(setPortalIsLoading(true))
    const { audiobooks } = yield call(apiGET, `/audiobooks`, { archived: true })
    yield put({ type: Books.RECEIVE, payload: audiobooks })
    yield put({ type: Portal.CLEAR_LOADED_BOOKS })
    yield put(setPortalIsLoading(false))
  } catch (e) {
    console.error(e)
  }
}

function* fetchReaderBookList() {
  try {
    yield put(setPortalIsLoading(true))
    const { audiobooks } = yield call(apiGET, `/audiobooks`)
    if (audiobooks) {
      yield put({ type: 'READER_BOOK_LIST', payload: audiobooks })
      yield put(setPortalIsLoading(false))
    }
  } catch (e) {
    console.error(e)
  }
}

function* fetchCeliaBookList() {
  try {
    yield put(setPortalIsLoading(true))
    const { audiobooks } = yield call(apiGET, `/audiobooks`)
    yield put({ type: RECEIVE_CELIA_BOOKS, payload: audiobooks })
    yield put(setPortalIsLoading(false))
  } catch (e) {
    console.error(e)
  }
}

function* fetchCorrectionsBookList() {
  try {
    yield put(setPortalIsLoading(true))
    const res = yield call(apiGET, `/audiobooks`)
    yield put({ type: RECEIVE_CORRECTION_BOOKS, payload: res })
    yield put(setPortalIsLoading(false))
  } catch (e) {
    console.error(e)
  }
}

function* addReaderCandidate(action) {
  const { organizationId, isbn, id: bId } = action.payload.book
  const { id } = action.payload.reader
  const data = { userId: id }
  try {
    const candidate = yield call(apiPOST, `/organizations/${organizationId}/audiobooks/${isbn}/readers`, data)
    console.log(`> Added reader candidate for ${isbn}`, candidate)
    yield all([
      put({ type: BookActions.ADD_READER_CANDIDATE, payload: { bookId: bId, candidate } }),
      put(fetchBook(organizationId, isbn)),
    ])
  } catch (e) {
    console.log(`> Failed to add reader candidate for book ${isbn}`, e)
  }
}

function* deleteReaderCandidate(action) {
  const { organizationId, isbn, id: bId } = action.payload.book
  const { candidate } = action.payload
  const { id: cId } = candidate
  try {
    const { status } = yield call(apiDELETE, `/organizations/${organizationId}/audiobooks/${isbn}/readers/${cId}`)
    if (status === 'OK') {
      console.log('> Deleted reader successfully', candidate)
      yield all([
        put({ type: BookActions.REMOVE_READER_CANDIDATE, payload: { bookId: bId, candidateId: cId } }),
        put(fetchBook(organizationId, isbn)),
      ])
    }
  } catch (e) {
    console.error(e)
  }
}

// When the reader updates the current reading status of the book
function* updateCandidateState(action) {
  console.log(`payload: ${JSON.stringify(action.payload)}`)

  const {
    book: { organizationId, isbn },
  } = action.payload

  const putPayload = pick(action.payload, [
    'newState',
    'newAudiobookState',
    'readerProgress',
    'readerDuration',
    'readerComments',
  ])

  try {
    yield call(
      apiPUT,
      `/organizations/${organizationId}/audiobooks/${isbn}/readers`,
      putPayload,
      { headers: { 'If-Unmodified-Since': action.payload.book.lastUpdated } },
      false
    )
    yield fetchReaderBookList()
  } catch (e) {
    const { response } = e

    console.error('> Failed to update the status of an audiobook', e, response)
    // TODO: Use only one common type for HTTP errors and pass a whole error type object
    if (response.status === 409) {
      yield put({ type: Portal.ERROR_409, payload: { callingFunction: 'updateCandidateState' } })
    } else {
      const message = response ? response.data.message : e.message
      yield put({ type: Portal.ERROR_GENERAL, payload: message })
    }
  }
}

export function* fetchReaderCandidates(action) {
  const { organizationId, isbn } = action.payload.book
  try {
    const res = yield call(apiGET, `/organizations/${organizationId}/audiobooks/${isbn}/readers`)
    if (res) {
      const { candidates } = res
      // console.log(`> Received reader candidates for ${isbn}`, candidates)
      yield put({ type: BookActions.UPDATE_READER_CANDIDATES, payload: { bookId: isbn, candidates } })
    }
  } catch (e) {
    console.log(`> Failed to fetch reader candidates for book ${isbn}`, e)
  }
}

function* confirmReaderCandidate(action) {
  const { book, candidate } = action.payload
  const { organizationId, isbn } = book
  try {
    const res = yield call(
      apiPUT,
      `/organizations/${organizationId}/audiobooks/${isbn}/readers/${candidate.id}`,
      {},
      {
        headers: { 'If-Unmodified-Since': book.lastUpdated },
      }
    )

    const { status } = res

    if (status === 'OK') {
      console.log('> Confirmed reader successfully', candidate)
      yield put(fetchReaderCandidatesAction(book))
      yield put(fetchBook(organizationId, isbn))
    }
  } catch (error) {
    console.log(`> Failed to confirm reader candidate for book ${isbn}`, error)

    const { response } = error

    if (response.status === 409) {
      yield put({ type: Portal.ERROR_409, payload: { callingFunction: 'confirmReaderCandidate' } })
    } else {
      const message = response ? response.data.message : error.message
      yield put({ type: Portal.ERROR_GENERAL, payload: message })
    }
  }
}

function* fetchBookData(action) {
  const { book } = action.payload
  const { organizationId, isbn } = book

  try {
    yield put({ type: Portal.LOADING_BOOK, payload: { isbn } })
    yield all([
      // run these parallel
      put(fetchSingleOrganization(organizationId)),
      fetchBookAttachments({ payload: { organizationId, isbn } }),
      fetchReaderCandidates({ payload: { book } }),
      put(fetchOrganizationBookDistributions(organizationId, isbn)),
      put(fetchBook(organizationId, isbn)),
    ])
    yield put({ type: Portal.LOADING_BOOK_DONE, payload: { isbn } })
  } catch (e) {
    console.error(e)
  }
}

// Generator function for fetching attachments for a single book by isbn.
function* fetchBookAttachments(action) {
  const { organizationId, isbn } = action.payload

  try {
    const credentials = yield select((state) => state.models.pronunciations.credentials)
    const query = credentials
      ? {
          credentialId: decodeURIComponent(credentials.credentialId),
        }
      : undefined
    const res = yield call(apiGET, `/organizations/${organizationId}/audiobooks/${isbn}/attachments`, query)
    const exists = res && res.attachments.length > 0
    if (exists) {
      for (let attachment of res.attachments) {
        yield put({ type: Attachment.RECEIVE, payload: { ...attachment, isbn, organizationId } })
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export function* deleteAttachmentSaga(action) {
  const attachment = action.payload
  const { organizationId, isbn, id } = attachment
  try {
    const res = yield call(apiDELETE, `/organizations/${organizationId}/audiobooks/${isbn}/attachments/${id}`)
    if (res.status === 'OK') {
      yield put({ type: Attachment.REMOVED, payload: attachment })
    }
  } catch (e) {
    console.error(e)
  }
}

function* bookSaga() {
  yield takeLatest(Books.REQUEST, fetchAllBooks)
  yield takeLatest(Books.REQUEST_ALSO_ARCHIVED, fetchAlsoArchived)
  yield takeLatest(BookActionsRequest.ADD_READER_CANDIDATE, addReaderCandidate)
  yield takeLatest(BookActionsRequest.REMOVE_READER_CANDIDATE, deleteReaderCandidate)
  yield takeLatest(BookActions.FETCH_READER_CANDIDATES, fetchReaderCandidates)
  yield takeLatest(BookActions.CONFIRM_READER_CANDIDATE, confirmReaderCandidate)
  yield takeLatest(BookActions.UPDATE_READER_CANDIDATE_STATE, updateCandidateState)
  yield takeLatest(FETCH_CELIA_BOOKS, fetchCeliaBookList)
  yield takeLatest(FETCH_CORRECTION_BOOKS, fetchCorrectionsBookList)
  yield takeLatest(CorrectionsListenerActions.CLAIM_BOOK, claimCorrectionsBook)
  yield takeLatest(CorrectionsListenerActions.VERIFY_COMPLETE, verifyListenComplete)
  yield takeLatest(Books.REFRESH, refreshBooks)
  yield takeLatest(Reader.FETCH_BOOKS, fetchReaderBookList)
  yield takeEvery(BookActions.FETCH_ALL_DATA, fetchBookData)
  yield takeEvery(BookActions.FETCH_ATTACHMENTS, fetchBookAttachments)
  yield takeEvery(Attachment.DELETE, deleteAttachmentSaga)
}

export default bookSaga
