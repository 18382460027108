import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AdminRoute from '../admin-route'
import { ReaderRoute, CeliaRoute, CorrectionsListenerRoute, GuestRoute } from '../external-routes'
import WindowFocusHandler from 'components/WindowFocusHandler'

import InvitationPage from './invitation'
import Login from './login'
import { ToastLayer } from './toast-layer'

const AdminPage = lazy(() => import(/* webpackChunkName: "admin.main" */ './admin'))
const AdminBookView = lazy(() => import(/* webpackChunkName: "admin.books" */ './book-list'))
const OrganizationPage = lazy(() => import(/* webpackChunkName: "organization.view" */ './organization'))
const OrganizationBookView = lazy(() => import(/* webpackChunkName: "organization.book.view" */ './organization-book'))
const ReaderView = lazy(() => import(/* webpackChunkName: "reader.view" */ './reader'))
const CeliaView = lazy(() => import(/* webpackChunkName: "celia.view" */ './celia'))
const CorrectionsView = lazy(() => import(/* webpackChunkName: "corrections.view" */ './corrections'))
const PronunciationGuide = lazy(() =>
  import(/* webpackChunkName: "pronunciation.guide.view" */ './pronunciation-guide')
)

import { PageContainer } from 'components'
import Protected from 'components/protected'
import { Loading } from 'components/loading'
import { useDispatcher } from '../hooks'
import Header from 'components/header'

const App = () => {
  const { portalDispatcher } = useDispatcher()
  const isGuestRoute = window.location.pathname.startsWith('/guest')

  window.addEventListener('resize', (e) => {
    if (e.target.innerWidth <= 768) {
      portalDispatcher.setMobileMode(true)
    } else {
      portalDispatcher.setMobileMode(false)
    }
  })

  return (
    <React.Fragment>
      <ToastContainer autoClose={false} transition={Slide} position={toast.POSITION.TOP_CENTER} />
      <WindowFocusHandler />
      <Header />
      <PageContainer>
        <ToastLayer />
        {!isGuestRoute && <Login />}
        <Suspense fallback={<Loading dark />}>
          <Switch>
            <>
              <Protected>
                <AdminRoute exact path="/" component={AdminPage} />
                <Route exact path="/invitation/:nonce" component={InvitationPage} />
                <Route exact path="/organizations/:organizationId" component={OrganizationPage} />
                <Route exact path="/organizations/:organizationId/:isbn" component={OrganizationBookView} />
                <Route
                  exact
                  path="/organizations/:organizationId/:isbn/pronunciation-guide"
                  component={PronunciationGuide}
                />
                <CeliaRoute exact path="/celia" component={CeliaView} />
                <ReaderRoute exact path="/reader" component={ReaderView} />
                <ReaderRoute
                  exact
                  path="/reader/pronunciation-guide/:organizationId/:isbn"
                  component={PronunciationGuide}
                />
                <AdminRoute exact path="/booklist" component={AdminBookView} />
                <CorrectionsListenerRoute exact path="/corrections" component={CorrectionsView} />
                <CorrectionsListenerRoute
                  exact
                  path="/corrections/pronunciation-guide/:organizationId/:isbn"
                  component={PronunciationGuide}
                />
              </Protected>
              <GuestRoute
                exact
                path="/guest/pronunciation-guide/:organizationId/:isbn"
                component={PronunciationGuide}
              />
            </>
          </Switch>
        </Suspense>
      </PageContainer>
    </React.Fragment>
  )
}

export default App
