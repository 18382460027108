import {
  fetchReaderBooklistAction,
  addReaderCandidate,
  confirmReaderCandidate,
  deleteReaderCandidate,
  updateCandidateState,
} from '.'

export class ReaderDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }
  fetchReaderBooklist = () => this.dispatch(fetchReaderBooklistAction())

  addReaderCandidate = (book, reader) => this.dispatch(addReaderCandidate(book, reader))

  confirmReaderCandidate = (book, candidate) => this.dispatch(confirmReaderCandidate(book, candidate))

  deleteReaderCandidate = (book, candidate) => this.dispatch(deleteReaderCandidate(book, candidate))

  updateCandidateState = (newStateParameters) => this.dispatch(updateCandidateState(newStateParameters))
}
