import { withPayload, actionCreator } from '../common'

export const Portal = {
  LOADING: '@@portal/LOADING',
  LOADING_BOOK: '@@portal/LOADING_BOOK',
  LOADING_BOOK_DONE: '@@portal/LOADING_BOOK_DONE',
  CLEAR_LOADED_BOOKS: '@@portal/CLEAR_LOADED_BOOKS',
  MOBILE_MODE: '@@portal/MOBILE_MODE',
  ERROR_409: '@@portal/NETWORK_ERROR_409', // TODO: replace this with a general HTTP_ERROR
  ERROR_GENERAL: '@@portal/NETWORK_ERROR_GENERAL',
  CLEAR_ERRORS: '@@portal/CLEAR_ERRORS',
  MESSAGE: '@@portal/MESSAGE',
  CLEAR_MESSAGES: '@@portal/CLEAR_MESSAGES',
  VIEW_LOAD: '@@portal/VIEW_LOAD',
  TABLE_VISIT_RETURN_STATE_SET_ISBN: '@@portal/TABLE_VISIT_RETURN_STATE_SET_ISBN',
  TABLE_VISIT_RETURN_STATE_CLEAR_ISBN: '@@portal/TABLE_VISIT_RETURN_STATE_CLEAR_ISBN',
  TABLE_VISIT_RETURN_STATE_SET_SORT_AND_FILTER: '@@portal/TABLE_VISIT_RETURN_STATE_SET_SORT_AND_FILTER',
  EDITING_BOOK: '@@portal/EDITING_BOOK',
  ADMIN_TABLE_SORT: '@@portal/ADMIN_TABLE_SORT',
  BLUR: '@@portal/BLUR',
  FOCUS: '@@portal/FOCUS',
}

export const setPortalIsLoading = (value) => withPayload(Portal.LOADING, value)

export const clearMessages = () => actionCreator(Portal.CLEAR_MESSAGES)
export const clearErrors = () => actionCreator(Portal.CLEAR_ERRORS)

export const setMobileMode = (value) => withPayload(Portal.MOBILE_MODE, value)
export const sendMessage = (message) => withPayload(Portal.MESSAGE, message)
export const setView = (view) => withPayload(Portal.VIEW_LOAD, view)
export const clearView = () => withPayload(Portal.VIEW_LOAD, null)
export const setTableVisitReturnStateIsbn = (viewedIsbn) =>
  withPayload(Portal.TABLE_VISIT_RETURN_STATE_SET_ISBN, { viewedIsbn })
export const clearTableVisitReturnStateIsbn = () => actionCreator(Portal.TABLE_VISIT_RETURN_STATE_CLEAR_ISBN)
export const setTableVisitReturnStateSortAndFilter = (sortBy, filters) =>
  withPayload(Portal.TABLE_VISIT_RETURN_STATE_SET_SORT_AND_FILTER, { sortBy, filters })

export const setEditableBook = (book) => withPayload(Portal.EDITING_BOOK, book)
export const sortAdminTable = (sortData) => withPayload(Portal.ADMIN_TABLE_SORT, sortData)

export const onPortalBlur = () => actionCreator(Portal.BLUR)
export const onPortalFocus = () => actionCreator(Portal.FOCUS)

export const setError409 = (message) => withPayload(Portal.ERROR_409, message)
