import {
  createOrganization,
  saveOrganization,
  fetchSingleOrganization,
  fetchOrganizations,
  fetchOrganizationUsers,
  fetchBook,
  fetchOrganizationBooks,
  fetchOrganizationBookDistributions,
  addPublisher,
  updatePublisher,
  deleteBook,
  createBookAction,
  saveBook,
  updateBookAction,
  toggleBookCreationAction,
  fetchOrganizationBookFromScratch,
  distributeBook,
  updateReaderComments,
  updateListenerComments,
  toggleByProductionType,
  updateOrg,
} from './creators'

export class OrganizationDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }
  createOrganization = (organization) => this.dispatch(createOrganization(organization))

  saveOrganization = (organization) => this.dispatch(saveOrganization(organization))

  fetchSingleOrganization = (organizationId) => this.dispatch(fetchSingleOrganization(organizationId))

  fetchOrganizations = () => this.dispatch(fetchOrganizations())

  fetchOrganizationUsers = (organizationId) => this.dispatch(fetchOrganizationUsers(organizationId))

  fetchBook = (organizationId, isbn) => this.dispatch(fetchBook(organizationId, isbn))

  fetchOrganizationBooks = (id, fetchAlsoArchived) => this.dispatch(fetchOrganizationBooks(id, fetchAlsoArchived))

  fetchOrganizationBookDistributions = (organizationId, isbn) =>
    this.dispatch(fetchOrganizationBookDistributions(organizationId, isbn))

  addPublisher = (organizationId, name) => this.dispatch(addPublisher(organizationId, name))

  updatePublisher = (publisher) => this.dispatch(updatePublisher(publisher))

  deleteBook = (organizationId, isbn) => this.dispatch(deleteBook(organizationId, isbn))

  createBook = (book) => this.dispatch(createBookAction(book))

  saveBook = (book) => this.dispatch(saveBook(book))

  updateBook = (field, value, book) => this.dispatch(updateBookAction(field, value, book))

  toggleBookCreation = () => this.dispatch(toggleBookCreationAction())

  fetchOrganizationBookFromScratch = (organizationId, isbn) =>
    this.dispatch(fetchOrganizationBookFromScratch(organizationId, isbn))

  distributeBook = (book) => this.dispatch(distributeBook(book))

  updateReaderComments = (payload) => this.dispatch(updateReaderComments(payload))

  updateListenerComments = (payload) => this.dispatch(updateListenerComments(payload))

  toggleByProductionType = (payload) => this.dispatch(toggleByProductionType(payload))

  updateOrganization = (payload) => this.dispatch(updateOrg(payload))
}
