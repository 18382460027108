import { combineReducers } from 'redux'
import attachmentsReducer from './attachments'
import audioReducer from './audio'
import booksReducer from './books'
import distributionsReducer from './distributions'
import distributorsReducer from './distributors'
import invitationsReducer from './invitations'
import organizationsReducer from './organizations'
import readerReducer from './reader'
import pronunciationsReducer from './pronunciations'

export default combineReducers({
  attachments: attachmentsReducer,
  audio: audioReducer,
  books: booksReducer,
  distributions: distributionsReducer,
  distributors: distributorsReducer,
  invitations: invitationsReducer,
  organizations: organizationsReducer,
  reader: readerReducer,
  pronunciations: pronunciationsReducer,
})
