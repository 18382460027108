import { claimBook, verifyListenComplete } from '.'

export class CorrectionsDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }
  claimBook = (organizationId, isbn, lastUpdated, userId) =>
    this.dispatch(claimBook(organizationId, isbn, lastUpdated, userId))
  verifyListenComplete = (organizationId, isbn, lastUpdated, comments, correctionsDuration) =>
    this.dispatch(verifyListenComplete(organizationId, isbn, lastUpdated, comments, correctionsDuration))
}
