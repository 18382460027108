import { useDispatch } from 'react-redux'

import { BooksDispatcher } from 'store/actions/books/dispatcher'
import { OrganizationDispatcher } from 'store/actions/organization/dispatcher'
import { DistributorDispatcher } from 'store/actions/distributor/dispatcher'
import { ReaderDispatcher } from 'store/actions/reader/dispatcher'
import { UserDispatcher } from 'store/actions/user/dispatcher'
import { InvitationDispatcher } from 'store/actions/invitation/dispatcher'
import { PortalDispatcher } from 'store/actions/portal/dispatcher'
import { CorrectionsDispatcher } from 'store/actions/corrections/dispatcher'
import { PronunciationsDispatcher } from '../store/actions/pronunciations/dispatcher'

export const useDispatcher = () => {
  const dispatch = useDispatch()

  return {
    booksDispatcher: new BooksDispatcher(dispatch),
    organizationDispatcher: new OrganizationDispatcher(dispatch),
    distributorDispatcher: new DistributorDispatcher(dispatch),
    readerDispatcher: new ReaderDispatcher(dispatch),
    userDispatcher: new UserDispatcher(dispatch),
    invitationDispatcher: new InvitationDispatcher(dispatch),
    portalDispatcher: new PortalDispatcher(dispatch),
    correctionsDispatcher: new CorrectionsDispatcher(dispatch),
    pronunciationsDispatcher: new PronunciationsDispatcher(dispatch),
  }
}
