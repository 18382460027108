import React from 'react'
import { connect } from 'react-redux'
import { LOGGED_IN } from 'store/reducers'

const Protected = ({ loggedIn, children }) => {
  if (!loggedIn) {
    return null
  }
  return <div>{children}</div>
}

const mapStateToProps = (state) => ({
  loggedIn: state.user.state === LOGGED_IN,
})

export default connect(mapStateToProps)(Protected)
