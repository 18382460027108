import React from 'react'

const Bookmark = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path fill="currentColor" d="M34 6H14c-2.21 0-3.98 1.79-3.98 4L10 42l14-6 14 6V10c0-2.21-1.79-4-4-4z" />
  </svg>
)
const CheckCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M24 4C12.95 4 4 12.95 4 24c0 11.04 8.95 20 20 20 11.04 0 20-8.96 20-20 0-11.05-8.96-20-20-20zm-4 30L10 24l2.83-2.83L20 28.34l15.17-15.17L38 16 20 34z"
    />
  </svg>
)

const Close = (
  <svg
    width="16"
    height="16"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128.56 128.56"
  >
    <title>miinus_pallo</title>
    <path
      fill="#fff"
      d="M511.56,448.24A64.28,64.28,0,1,0,447.28,384a64.28,64.28,0,0,0,64.28,64.28"
      transform="translate(-447.28 -319.68)"
    />
    <rect fill="#ea5b61" x="16.43" y="61.09" width="95.7" height="6.38" />
  </svg>
)

const GetApp = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path fill="#FB5B60" d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z" />
  </svg>
)
const Delete = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"
    />
  </svg>
)

const Expand = (
  <svg
    width="16"
    height="16"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128.56 128.56"
  >
    <title>plus_pallo</title>
    <path
      fill="#fff"
      d="M512,448.24A64.28,64.28,0,1,0,447.74,384,64.28,64.28,0,0,0,512,448.24"
      transform="translate(-447.74 -319.68)"
    />
    <polygon
      fill="#ea5b61"
      points="67.47 61.09 67.47 13.24 61.09 13.24 61.09 61.09 16.43 61.09 16.43 67.47 61.09 67.47 61.09 115.32 67.47 115.32 67.47 67.47 112.13 67.47 112.13 61.09 67.47 61.09"
    />
  </svg>
)

const Home = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path fill="currentColor" d="M20 40V28h8v12h10V24h6L24 6 4 24h6v16z" />
  </svg>
)
const Lock = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M36 16h-2v-4c0-5.52-4.48-10-10-10S14 6.48 14 12v4h-2c-2.21 0-4 1.79-4 4v20c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V20c0-2.21-1.79-4-4-4zM24 34c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm6.2-18H17.8v-4c0-3.42 2.78-6.2 6.2-6.2 3.42 0 6.2 2.78 6.2 6.2v4z"
    />
  </svg>
)
const LockOpen = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M24 34c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm12-18h-2v-4c0-5.52-4.48-10-10-10S14 6.48 14 12h3.8c0-3.42 2.78-6.2 6.2-6.2 3.42 0 6.2 2.78 6.2 6.2v4H12c-2.21 0-4 1.79-4 4v20c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V20c0-2.21-1.79-4-4-4zm0 24H12V20h24v20z"
    />
  </svg>
)
const PlayArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path fill="#FB5B60" d="M16 10v28l22-14z" />
  </svg>
)

const Reload = (
  <svg
    width="22"
    height="22"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128.73 128.73"
  >
    <title>reload_pallo</title>
    <path
      fill="#fff"
      d="M512.37,447.89A64.37,64.37,0,1,0,448,383.52a64.37,64.37,0,0,0,64.36,64.37"
      transform="translate(-448.01 -319.16)"
    />
    <path
      fill="#ea5b61"
      d="M485.61,383.62c-.16-6.64,3.18-12.86,4.08-14.42.13-.22.27-.43.42-.64,14.41-19.28,34.8-8.71,34.8-8.71l6.55-6.55c-18.71-11-37.05-4.31-46.41,7.3s-8.43,23-8.43,23H463.15l18,18,18-18Z"
      transform="translate(-448.01 -319.16)"
    />
    <path
      fill="#ea5b61"
      d="M561.6,383.43l-18-18-18,18h13.48c.16,6.64-3.18,12.86-4.09,14.42a6.4,6.4,0,0,1-.42.64c-14.4,19.28-34.8,8.71-34.8,8.71l-6.55,6.55c18.72,11,37.06,4.31,46.42-7.3s8.42-23,8.42-23Z"
      transform="translate(-448.01 -319.16)"
    />
  </svg>
)

const ReportProblem = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path fill="currentColor" d="M2 42h44L24 4 2 42zm24-6h-4v-4h4v4zm0-8h-4v-8h4v8z" />
  </svg>
)
const Schedule = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M23.99 4C12.94 4 4 12.95 4 24s8.94 20 19.99 20C35.04 44 44 35.05 44 24S35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16zm1-26h-3v12l10.49 6.3L34 29.84l-9-5.34z"
    />
  </svg>
)
const Search = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M31 28h-1.59l-.55-.55C30.82 25.18 32 22.23 32 19c0-7.18-5.82-13-13-13S6 11.82 6 19s5.82 13 13 13c3.23 0 6.18-1.18 8.45-3.13l.55.55V31l10 9.98L40.98 38 31 28zm-12 0c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9z"
    />
  </svg>
)
const ZoomIn = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm2.5-4h-2v2H9v-2H7V9h2V7h1v2h2v1z"
    />
  </svg>
)
const ZoomOut = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"
    />
  </svg>
)

const icons = {
  bookmark: Bookmark,
  check_circle: CheckCircle,
  close: Close,
  get_app: GetApp,
  delete: Delete,
  expand: Expand,
  home: Home,
  lock: Lock,
  lock_open: LockOpen,
  play_arrow: PlayArrow,
  reload: Reload,
  report_problem: ReportProblem,
  schedule: Schedule,
  search: Search,
  zoom_in: ZoomIn,
  zoom_out: ZoomOut,
}

const Icon = ({ icon }) => {
  const IconComponent = icons[icon]
    ? icons[icon]
    : (() => {
        console.warn(`Invalid icon ${icon}!`) // eslint-disable-line
        return ReportProblem
      })()
  return IconComponent
}

export default Icon
