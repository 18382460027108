import { call, put, select, takeLatest } from 'redux-saga/effects'

import { apiGET, apiPOST, apiPUT } from '../../api'
import {
  GuideSet,
  PronunciationCredentials,
  PronunciationPublishResponse,
  PronunciationResponse,
  PronunciationUpdateActionResponse,
  TTSResponse,
} from '../../typings'
import { generateId } from '../../utils'
import {
  Portal,
  PRONUNCIATIONS_FETCH,
  PRONUNCIATIONS_FETCH_FAILURE,
  PRONUNCIATIONS_FETCH_SUCCESS,
  PRONUNCIATIONS_PUBLISH,
  PRONUNCIATIONS_PUBLISH_FAILURE,
  PRONUNCIATIONS_PUBLISH_SUCCESS,
  PRONUNCIATIONS_UPDATE,
  PRONUNCIATIONS_UPDATE_FAILURE,
  PRONUNCIATIONS_UPDATE_SUCCESS,
  PronunciationsFetchAction,
  PronunciationsPublishAction,
  PronunciationsUpdateAction,
  PRONUNCIATIONS_FETCH_TTS,
  PRONUNCIATIONS_FETCH_TTS_SUCCESS,
  PRONUNCIATIONS_FETCH_TTS_FAILURE,
  PRONUNCIATIONS_SET_COPY,
} from '../actions'

/**
 * Generate item ids for guide set items.
 */
const addGuideSetItemIds = (guideSet: GuideSet) => {
  return {
    ...guideSet,
    items: guideSet.items.map((item) => ({
      ...item,
      id: generateId(),
    })),
  }
}

function* fetchPronunciations({ organizationId, isbn }: PronunciationsFetchAction) {
  try {
    const credentials: PronunciationCredentials = yield select((state) => state.models.pronunciations.credentials)
    const query = credentials
      ? {
          credentialId: credentials.credentialId,
        }
      : undefined
    const pronunciations: PronunciationResponse = yield call(
      apiGET,
      `/organizations/${organizationId}/audiobooks/${isbn}/pronunciation`,
      query
    )
    const guideSets = pronunciations.guideSets.map((guideSet) => addGuideSetItemIds(guideSet))
    yield put({ type: PRONUNCIATIONS_FETCH_SUCCESS, payload: { guideSets, isbn } })
    yield put({ type: PRONUNCIATIONS_SET_COPY, payload: { guideSets } })
  } catch (error) {
    yield put({ type: PRONUNCIATIONS_FETCH_FAILURE })
    const message = 'Lukuohjeen tietojen haku epäonnistui'
    yield put({ type: Portal.ERROR_GENERAL, payload: message })
    console.error(error)
  }
}

function* updatePronunciations({ organizationId, isbn, lastUpdated, payload }: PronunciationsUpdateAction) {
  try {
    const credentials: PronunciationCredentials = yield select((state) => state.models.pronunciations.credentials)
    const credentialsPayload = credentials
      ? {
          credentialId: credentials.credentialId,
        }
      : undefined
    const updateResponse: PronunciationUpdateActionResponse = yield call(
      apiPUT,
      `/organizations/${organizationId}/audiobooks/${isbn}/pronunciation`,
      { ...payload, ...credentialsPayload },
      {
        headers: { 'If-Unmodified-Since': lastUpdated },
      }
    )
    yield put({ type: PRONUNCIATIONS_UPDATE_SUCCESS, payload: { guideSet: addGuideSetItemIds(updateResponse), isbn } })
  } catch (error) {
    yield put({ type: PRONUNCIATIONS_UPDATE_FAILURE })
    const message = 'Tallennus epäonnistu'
    yield put({ type: Portal.ERROR_GENERAL, payload: message })
    console.error(error)
  }
}

function* publishPronunciations({ organizationId, isbn }: PronunciationsPublishAction) {
  try {
    const credentials: PronunciationCredentials = yield select((state) => state.models.pronunciations.credentials)
    const payload = credentials
      ? {
          credentialId: credentials.credentialId,
        }
      : undefined
    const liveGuideSet: PronunciationPublishResponse = yield call(
      apiPOST,
      `/organizations/${organizationId}/audiobooks/${isbn}/pronunciation/publish`,
      payload
    )
    yield put({ type: PRONUNCIATIONS_PUBLISH_SUCCESS, payload: { guideSet: liveGuideSet, isbn } })
    const message = 'Lukuohjeen julkaisu onnistui'
    yield put({ type: Portal.MESSAGE, payload: message })
  } catch (error) {
    yield put({ type: PRONUNCIATIONS_PUBLISH_FAILURE })
    const message = 'Lukuohjeen julkaisu epäonnistui'
    yield put({ type: Portal.ERROR_GENERAL, payload: message })
    console.error(error)
  }
}

function* fetchTextToSpeechSettings() {
  try {
    const credentials: PronunciationCredentials = yield select((state) => state.models.pronunciations.credentials)
    const query = credentials
      ? {
          credentialId: credentials.credentialId,
        }
      : undefined
    const ttsSettings: TTSResponse = yield call(apiGET, `/tts`, query)
    yield put({ type: PRONUNCIATIONS_FETCH_TTS_SUCCESS, payload: ttsSettings })
  } catch (error) {
    yield put({ type: PRONUNCIATIONS_FETCH_TTS_FAILURE })
    const message = 'TTS tietojen haku epäonnistui'
    yield put({ type: Portal.ERROR_GENERAL, payload: message })
    console.error(error)
  }
}

function* pronunciationsSaga() {
  yield takeLatest(PRONUNCIATIONS_FETCH, fetchPronunciations)
  yield takeLatest(PRONUNCIATIONS_UPDATE, updatePronunciations)
  yield takeLatest(PRONUNCIATIONS_PUBLISH, publishPronunciations)
  yield takeLatest(PRONUNCIATIONS_FETCH_TTS, fetchTextToSpeechSettings)
}

export default pronunciationsSaga
