import { all, call, put, takeLatest } from 'redux-saga/effects'
import { apiGET, apiPUT, apiPOST, apiDELETE } from 'src/api'
import { Distributors, Distributor, HYDRATE_DISTRIBUTORS, removeDistributor } from 'store/actions'
import { pick } from 'src/util'

const createDistributorRecord = (distributor) => {
  const distributorRecord = pick(distributor, [
    'name',
    'type',
    'format',
    'bitrate',
    'doubleStereoBitrate',
    'emails',
    'ftpScheme',
  ])
  if (distributor.ftpScheme !== 'NO_FTP') {
    distributorRecord.ftpAddress = distributor.ftpAddress
    distributorRecord.ftpPort = parseInt(distributor.ftpPort)
    distributorRecord.ftpUsername = distributor.ftpUsername
    distributorRecord.ftpPassword = distributor.ftpPassword
    distributorRecord.ftpPath = distributor.ftpPath
  }

  return distributorRecord
}

function* fetchDistributors() {
  let res
  try {
    res = yield call(apiGET, `/distributors`)
  } catch (e) {
    console.error(e)
  } finally {
    if (res && res.distributors) {
      yield put({ type: HYDRATE_DISTRIBUTORS, payload: res.distributors })
    }
  }
}

function* createDistributor({ payload }) {
  const distributorRecord = createDistributorRecord(payload)

  let newDistributor
  try {
    newDistributor = yield call(apiPOST, `/distributors`, distributorRecord)
  } catch (e) {
    const message = e.response ? e.response.data.message : e.message
    yield put({ type: '', payload: { form: 'distributor', message: message } })
    console.error(e)
  } finally {
    yield all([
      put({ type: Distributor.TOGGLE_DISTRIBUTOR_CREATION }),
      put({ type: Distributor.UPDATE_DISTRIBUTOR, payload: newDistributor }),
    ])
  }
}

function* saveDistributor({ payload }) {
  const distributor = payload
  const distributorRecord = createDistributorRecord(distributor)
  let updatedDistributor

  try {
    updatedDistributor = yield call(apiPUT, `/distributors/${distributor.id}`, distributorRecord)
  } catch (e) {
    console.error(e)
  } finally {
    yield put({ type: Distributor.UPDATE_DISTRIBUTOR, payload: updatedDistributor })
  }
}

function* deleteDistributor({ payload }) {
  try {
    const { id } = payload
    const res = yield call(apiDELETE, `/distributors/${id}`)

    if (res && res.status === 'OK') {
      yield put(removeDistributor({ id }))
    }
  } catch (e) {
    console.error(e)
  }
}

function* distributorSaga() {
  yield takeLatest(Distributors.FETCH, fetchDistributors)
  yield takeLatest(Distributors.CREATE, createDistributor)
  yield takeLatest(Distributors.SAVE, saveDistributor)
  yield takeLatest(Distributors.DELETE, deleteDistributor)
}

export default distributorSaga
