import firebase from 'firebase/compat/app'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'
import { apiGET, api } from './api'

const initFirebase = apiGET('/firebaseConfig')
  .then((config) => firebase.initializeApp(config))
  .then(() => firebase.auth())

export const firebaseAuth = initFirebase

export const signInOptions = {
  signInSuccessUrl: '/',
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        prompt: 'select_account',
      },
    },
    // Facebook login disabled for now
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
}

export const bindUI = (el, options = signInOptions) => {
  firebaseAuth.then((auth) => {
    const ui = new firebaseui.auth.AuthUI(auth)
    ui.start(el, { ...signInOptions, ...options })
  })
}

export const refreshToken = () => {
  // console.log('refreshing token')
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then((token) => api.setToken(token))
    .catch((error) => console.log(error))
}
