import { updateWithId, updateWithGroupedBy, removeWithId, remove } from './common'
import { Attachment } from 'store/actions'

const initialState = {
  byId: {},
  idsByBookId: {},
}

const updateWithGroupedByISBN = updateWithGroupedBy('isbn')

const attachmentsReducer = (state = initialState, action) => {
  let values = action.payload

  switch (action.type) {
    case Attachment.RECEIVE:
      return {
        byId: updateWithId(state.byId, values),
        idsByBookId: updateWithGroupedByISBN(state.idsByBookId, values),
      }
    case Attachment.REMOVED:
      return {
        byId: removeWithId(state.byId, values.id),
        idsByBookId: { ...state.idsByBookId, [values.isbn]: remove(state.idsByBookId[values.isbn] || [], values.id) },
      }

    default:
      return state
  }
}

export default attachmentsReducer
