import type { PronunciationItem, SearchItem } from '../typings'

/**
 * Original code from:
 * @see https://github.com/bvaughn/highlight-words-core/blob/master/src/utils.js
 */

export type Chunk = {
  highlight: boolean
  id: string | undefined
  start: number
  end: number
}

/**
 * Creates an array of chunk objects representing both higlightable and non highlightable pieces of text that match each search word.
 * @return Array of "chunks" (where a Chunk is { start:number, end:number, highlight:boolean })
 */
export const findWords = ({
  caseSensitive = false,
  findChunks = defaultFindChunks,
  searchWords,
  textToHighlight,
}: {
  caseSensitive?: boolean
  findChunks?: typeof defaultFindChunks
  searchWords: Array<SearchItem>
  textToHighlight: string
}): Array<Chunk> =>
  fillInChunks({
    chunksToHighlight: combineChunks({
      chunks: findChunks({
        caseSensitive,
        searchWords,
        textToHighlight,
      }),
    }),
    totalLength: textToHighlight ? textToHighlight.length : 0,
  })

/**
 * Takes an array of {start:number, end:number} objects and combines chunks that overlap into single chunks.
 * @return {start:number, end:number}[]
 */
export const combineChunks = ({ chunks }: { chunks: Chunk[] }): Chunk[] => {
  chunks = chunks
    .sort((first, second) => first.start - second.start)
    .reduce((processedChunks, nextChunk) => {
      // First chunk just goes straight in the array...
      if (processedChunks.length === 0) {
        return [nextChunk]
      } else {
        // ... subsequent chunks get checked to see if they overlap...
        const prevChunk = processedChunks.pop() as Chunk
        if (nextChunk.start <= prevChunk.end) {
          // It may be the case that prevChunk completely surrounds nextChunk, so take the
          // largest of the end indeces.
          const endIndex = Math.max(prevChunk.end, nextChunk.end)
          processedChunks.push({ highlight: false, start: prevChunk.start, end: endIndex, id: prevChunk.id })
        } else {
          processedChunks.push(prevChunk, nextChunk)
        }
        return processedChunks
      }
    }, [] as Chunk[])

  return chunks
}

/**
 * Examine text for any matches.
 * If we find matches, add them to the returned array as a "chunk" object ({start:number, end:number}).
 * @return {start:number, end:number}[]
 */
export const defaultFindChunks = ({
  caseSensitive,
  searchWords,
  textToHighlight,
}: {
  caseSensitive?: boolean
  searchWords: Array<SearchItem>
  textToHighlight: string
}): Array<Chunk> => {
  return searchWords
    .filter((searchItem) => searchItem.text) // Remove empty words
    .reduce((chunks, searchItem) => {
      const searchWord = escapeRegExpFn(searchItem.text)
      const regex = new RegExp(searchWord, caseSensitive ? 'g' : 'gi')

      let match
      while ((match = regex.exec(textToHighlight))) {
        let start = match.index
        let end = regex.lastIndex
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end, id: searchItem.id })
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++
        }
      }
      return chunks
    }, [] as Chunk[])
}

/**
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength number
 * @return {start:number, end:number, highlight:boolean}[]
 */
export const fillInChunks = ({
  chunksToHighlight,
  totalLength,
}: {
  chunksToHighlight: Array<Chunk>
  totalLength: number
}): Array<Chunk> => {
  const allChunks: any[] = []
  const append = (start: number, end: number, id: string | undefined, highlight: boolean) => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        id,
        highlight,
      })
    }
  }

  if (chunksToHighlight.length === 0) {
    append(0, totalLength, undefined, false)
  } else {
    let lastIndex = 0
    chunksToHighlight.forEach((chunk) => {
      append(lastIndex, chunk.start, chunk.id, false)
      append(chunk.start, chunk.end, chunk.id, true)
      lastIndex = chunk.end
    })
    append(lastIndex, totalLength, undefined, false)
  }
  return allChunks
}

export const escapeRegExpFn = (string: string): string => {
  // eslint-disable-next-line no-useless-escape
  return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
}

// Returns the page number where the selected word occurs for the first time.
export const findFirstOccurrencePage = (textToHighlight: string, textContent: string[], currentPage: number) => {
  let firstOccurrencePage = currentPage
  textContent.find((page, pageIndex) => {
    const lowerCasePage = page.toLowerCase()
    const highLightIndex = lowerCasePage.indexOf(textToHighlight.toLowerCase())
    if (highLightIndex > -1) {
      firstOccurrencePage = pageIndex + 1
      return firstOccurrencePage
    }
  })
  return firstOccurrencePage
}

export const getWordCount = (word: string, textContent: string[]) => {
  let wordCount = 0
  textContent.forEach((page) => {
    const lowerCasePage = page.toLowerCase()
    const wordsOnPage = lowerCasePage.split(word.toLowerCase()).length - 1
    wordCount += wordsOnPage
  })
  return wordCount
}

export const HIGHLIGHT_WORD_MIN_LENGTH = 4

export const getValidSearchWords = (items: PronunciationItem[]) => {
  return items
    .map((item) => {
      const text = item.lemma || item.text
      if (text.length >= HIGHLIGHT_WORD_MIN_LENGTH) {
        return { text, id: item.id }
      }
    })
    .filter((item): item is SearchItem => item !== undefined)
}
