import { createInvitation, toggleInvitationCreation, updateInvitation } from '.'

export class InvitationDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch
  }
  createInvitation = (invitation) => this.dispatch(createInvitation(invitation))
  toggleInvitationCreation = (type) => this.dispatch(toggleInvitationCreation(type))
  updateInvitation = (invitation) => this.dispatch(updateInvitation(invitation))
}
