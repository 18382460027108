import { withPayload, actionCreator } from '../common'
import { BookActions, BookActionsRequest } from '../books'

export const Reader = {
  FETCH_BOOKS: 'FETCH_READER_BOOKS',
  BOOK_LIST: 'READER_BOOK_LIST',
}

export const fetchReaderBooklistAction = () => actionCreator(Reader.FETCH_BOOKS)

export const addReaderCandidate = (book, reader) =>
  withPayload(BookActionsRequest.ADD_READER_CANDIDATE, { book, reader })

export const confirmReaderCandidate = (book, candidate) =>
  withPayload(BookActions.CONFIRM_READER_CANDIDATE, { book, candidate })

export const deleteReaderCandidate = (book, candidate) =>
  withPayload(BookActionsRequest.REMOVE_READER_CANDIDATE, { book, candidate })

export const updateCandidateState = (newStateParameters) =>
  withPayload(BookActions.UPDATE_READER_CANDIDATE_STATE, newStateParameters)
