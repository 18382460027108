import { combineReducers } from 'redux'
import modelReducer from './models'
import messageReducer from './message'
import transientReducer from './transient'
import userReducer from './user'
import portalReducer from './portal'

export { LOGGED_IN, LOGGED_OUT, IDENTIFIED, CHECKING } from './user'

export default combineReducers({
  models: modelReducer,
  transient: transientReducer,
  message: messageReducer,
  user: userReducer,
  portal: portalReducer,
})
