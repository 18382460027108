import React from 'react'
import { toast } from 'react-toastify'
import { useDispatcher } from '../../hooks'
import { GreyButton, Button } from '../buttons'
import { Actions, commonToastOptions, Heading, Text, Toast } from './StyledToasts'

const HttpErrorToast = (props) => {
  const { portalDispatcher } = useDispatcher()

  const { callingFunction } = props

  const errorNotificationBody =
    callingFunction === 'updateCandidateState' ? (
      <div>
        <Heading>Sivunumeromäärän tallentaminen epäonnistui</Heading>
        <Text>Palvelin palautti virheen 409. Kokeile myöhemmin uudelleen.</Text>
        <Text>Lataa sivu uudestaan päivittääksesi kirjalistauksen.</Text>
      </div>
    ) : (
      <div>
        <Heading>Kirjan tallentaminen epäonnistui</Heading>
        <Text>
          Tekemiäsi muokkauksia ei voitu tallentaa, koska toinen käyttäjä on samanaikaisesti muokannut kirjan tietoja.
        </Text>
        <Text>Lataa sivu uudestaan päivittääksesi kirjan tiedot.</Text>
      </div>
    )

  return (
    <Toast>
      {errorNotificationBody}
      <Actions>
        <GreyButton onClick={() => portalDispatcher.clearErrors()}>Peruuta</GreyButton>
        <div style={{ width: 32 }} />
        <Button onClick={() => window.location.reload()}>Päivitä</Button>
      </Actions>
    </Toast>
  )
}

HttpErrorToast.options = {
  ...commonToastOptions,
  position: toast.POSITION.BOTTOM_CENTER,
  closeOnClick: false,
  autoClose: 5000,
}

export default HttpErrorToast
