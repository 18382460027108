import {
  ORGANIZATION_BOOK_FILTER,
  ORGANIZATION_SORTING_CHANGED,
  ORGANIZATION_BOOK_CREATED,
  CLEAR_ORGANIZATION_BOOK_CREATED,
} from 'store/actions/books'

import { OrganizationBook, Organization } from 'store/actions/organization'

import { Distributor } from 'store/actions/distributor'
import { ProductionTypes } from '../../typings'

const initialState = {
  openDistributorById: null,
  organizationBookFilter: [],
  organizationBookExpand: {},
  organizationSortingMethod: null,
  organizationLatestBookCreated: null,
  modifiedOrgBook: null,
  distributeNow: null,
  productionTypeFilters: [ProductionTypes.SILENCIO, ProductionTypes.AANINEN],
}

const transientReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrganizationBook.UPDATE: {
      return {
        ...state,
        modifiedOrgBook: action.payload,
      }
    }
    case OrganizationBook.CLEAR_UPDATED: {
      return {
        ...state,
        modifiedOrgBook: null,
      }
    }
    case ORGANIZATION_BOOK_CREATED: {
      return {
        ...state,
        organizationLatestBookCreated: action.payload,
      }
    }
    case CLEAR_ORGANIZATION_BOOK_CREATED: {
      return {
        ...state,
        organizationLatestBookCreated: null,
      }
    }
    case ORGANIZATION_BOOK_FILTER: {
      return {
        ...state,
        organizationBookFilter: action.payload,
      }
    }
    case ORGANIZATION_SORTING_CHANGED: {
      return {
        ...state,
        organizationSortingMethod: action.payload,
      }
    }
    case Distributor.TOGGLE_DISTRIBUTOR_ROW: {
      const value = state.openDistributorById === action.payload.id ? null : action.payload.id
      return {
        ...state,
        openDistributorById: value,
      }
    }
    case OrganizationBook.DISTRIBUTE: {
      return {
        ...state,
        distributeNow: action.payload,
      }
    }
    case Organization.TOGGLE_BY_PRODUCTION_TYPE: {
      const { typeName } = action.payload
      const productionTypeFilters = [...state.productionTypeFilters]
      const index = productionTypeFilters.findIndex((value) => value === typeName)
      productionTypeFilters.includes(typeName)
        ? productionTypeFilters.splice(index, 1)
        : productionTypeFilters.push(typeName)
      return {
        ...state,
        productionTypeFilters,
      }
    }
    default:
      return state
  }
}

export default transientReducer
