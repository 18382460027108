import { withPayload } from '../common'

export const CorrectionsListenerActions = {
  CLAIM_BOOK: '@@corrections/CLAIM_BOOK',
  VERIFY_COMPLETE: '@@corrections/VERIFY_COMPLETE',
}

export const claimBook = (organizationId, isbn, lastUpdated, userId) =>
  withPayload(CorrectionsListenerActions.CLAIM_BOOK, { organizationId, isbn, lastUpdated, userId })
export const verifyListenComplete = (organizationId, isbn, lastUpdated, comments, correctionsDuration) =>
  withPayload(CorrectionsListenerActions.VERIFY_COMPLETE, {
    organizationId,
    isbn,
    lastUpdated,
    comments,
    correctionsDuration,
  })
