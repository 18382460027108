import { OrganizationActions, OrganizationBook, Organizations, Organization } from '.'
import { withPayload, actionCreator } from '../common'

export const createOrganization = (organization) => withPayload(Organization.CREATE, { organization })

export const saveOrganization = (organization) => withPayload(Organization.SAVE, { organization })

export const fetchSingleOrganization = (organizationId) => withPayload(Organization.FETCH, { organizationId })

export const fetchOrganizations = () => actionCreator(Organizations.FETCH)

export const fetchOrganizationUsers = (organizationId) => withPayload(Organization.FETCH_USERS, { organizationId })

export const fetchBook = (organizationId, isbn) => withPayload(OrganizationBook.REQUEST, { organizationId, isbn })

export const fetchOrganizationBooks = (id, fetchAlsoArchived) =>
  withPayload(OrganizationActions.REQUEST_BOOKS, { id, fetchAlsoArchived })

export const fetchOrganizationBookDistributions = (organizationId, isbn) =>
  withPayload(OrganizationBook.FETCH_DISTRIBUTIONS, { organizationId, isbn })

export const addPublisher = (organizationId, name) => withPayload(Organization.ADD_PUBLISHER, { organizationId, name })

export const updatePublisher = (publisher) => withPayload(Organization.UPDATE_PUBLISHER, { publisher })

export const deleteBook = (organizationId, isbn) => withPayload(OrganizationBook.DELETE, { organizationId, isbn })

export const createBookAction = (book) => withPayload(OrganizationBook.CREATE, { book })

export const saveBook = (book) => withPayload(OrganizationBook.SAVE, { book })

export const updateBookAction = (field, value, book) =>
  withPayload(OrganizationBook.UPDATE, { ...book, [field]: value, original: book })

export const toggleBookCreationAction = () => actionCreator(OrganizationBook.TOGGLE_CREATION)

export const fetchOrganizationBookFromScratch = (organizationId, isbn) =>
  withPayload(OrganizationBook.FROM_SCRATCH, { organizationId, isbn })

export const distributeBook = (book) => withPayload(OrganizationBook.DISTRIBUTE, book)

export const updateReaderComments = (payload) => withPayload(OrganizationBook.UPDATE_READER_COMMENTS, payload)

export const updateListenerComments = (payload) => withPayload(OrganizationBook.UPDATE_LISTENER_COMMENTS, payload)

export const toggleByProductionType = (payload) => withPayload(Organization.TOGGLE_BY_PRODUCTION_TYPE, payload)

export const updateOrg = (payload) => withPayload(Organization.RECEIVE, payload)
