import { createSelector } from 'reselect'

const getBooks = (state) => state.models.books

export const getBookCandidates = (bookId) => (state) => {
  return state.models.books.candidatesByBookId[bookId]
}

export const getReaderBooks = (state) => {
  return state.models.reader.books
}

export const getBookIdsByOrganizationId = (organizationId) => (state) =>
  state.models.books.idsByOrganizationId[organizationId]

export const getBooksById = (state) => state.models.books.byId

export const getAllBooksSelector = (state) => {
  return state.models.books.list
}

export const getBooksByIds = createSelector([getBooks], (books) => {
  return Object.values(books.byId)
})

export const getBookReaderCandidates = (bookId) => (state) => {
  return state.models.books.candidatesByBookId[bookId]
}

export const getBookAttachments = (bookId) => (state) => {
  return state.models.attachments.idsByBookId[bookId]
}

export const getBooksLoadingSelector = (state) => {
  return state.models.books.loading
}

export const getNewBookSelector = (state) => {
  return state.models.books.creating
}

export const getBookById = (id) => (state) => {
  return state.models.books.byId[id]
}

// Available to be claimed
export const getBooksInCorrectionsPool = (state) => {
  return state.models.books.correctionBooks.pool
}

export const getCompletedCorrectionsBooks = (state) => {
  return state.models.books.correctionBooks.claimed.filter((book) => book.correctionsStatus === 'COMPLETED')
}

export const getClaimedCorrectionsBook = (state) => {
  return state.models.books.correctionBooks.claimed?.filter((book) => book.correctionsStatus === 'CLAIMED')[0]
}

/**
 * Books that have been marked for the corrections listener but which are not yet ready to be corrected.
 * @param {object} state - Contains the whole current state
 * @returns {array} nextFourBooks - Four upcoming correction books with the closest (smallest) deadline
 */
export const getUpcomingCorrectionsBooks = (state) => {
  return state.models.books.correctionBooks.upcoming
}

export const getBookVerifier = (id) => (state) => {
  return state.models.books.byId[id].verifier
}

export const getPublishingPanelWidth = (state) => {
  return state.models.books.publishingPanelWidth
}
