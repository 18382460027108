import { useEffect } from 'react'
import { useDispatcher } from 'src/hooks'

const WindowFocusHandler = () => {
  const { portalDispatcher } = useDispatcher()
  // User has switched back to the tab
  const onFocus = () => {
    portalDispatcher.onFocus()
  }

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    portalDispatcher.onBlur()
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  })

  return null
}

export default WindowFocusHandler
