import './wdyr'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { ThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import createSagaMiddleware from 'redux-saga'

import Root from './views'
import i18n from './i18n'
import { theme } from './theme'
import reducers from 'store/reducers'
import { organizationSaga, bookSaga, distributorSaga, userSaga, invitationSaga, pronunciationsSaga } from 'store/sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewares = applyMiddleware(sagaMiddleware)

const store = createStore(reducers, composeWithDevTools(middlewares))

sagaMiddleware.run(organizationSaga)
sagaMiddleware.run(bookSaga)
sagaMiddleware.run(distributorSaga)
sagaMiddleware.run(userSaga)
sagaMiddleware.run(invitationSaga)
sagaMiddleware.run(pronunciationsSaga)

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway';
    background: ${theme.colors.grayBackground}; 
    /* min-width: 1100px; */
  }
  * , *:before, *:after {
    box-sizing: border-box;
  }

  input, textarea, select {
    font-size: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
  }

::-webkit-input-placeholder { /* WebKit browsers */
    font-size: 0.8rem;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: uppercase;
  font-size: 0.8rem;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: uppercase;
  font-size: 0.8rem;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: uppercase;
  font-size: 0.8rem;
}
::placeholder { /* Recent browsers */
  text-transform: uppercase;
  font-size: 0.8rem;
}
`

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <GlobalStyle />
            <Root />
          </React.Fragment>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)
