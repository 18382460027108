import { updateWithId } from './common'

import { TOGGLE_ORGANIZATION_CREATION, UPDATE_ORGANIZATION_USERS, Organizations, Organization } from 'store/actions'

const initialState = {
  creating: null,
  byId: {},
  usersByOrgId: {},
}

const organizationsReducer = (state = initialState, action) => {
  const values = action.payload

  switch (action.type) {
    case Organization.FETCH: {
      return {
        ...state,
        byId: {},
      }
    }
    case Organizations.RECEIVE:
      return {
        ...state,
        byId: values,
      }
    case Organization.RECEIVE:
      if (values.creating === true) {
        return {
          ...state,
          creating: values,
        }
      }
      return {
        ...state,
        byId: updateWithId(state.byId, values),
      }
    case TOGGLE_ORGANIZATION_CREATION:
      if (!state.creating) {
        return {
          ...state,
          creating: {
            name: '',
            email: '',
            receiveConfirmations: false,
            creating: true,
          },
        }
      }
      return {
        ...state,
        creating: null,
      }
    case UPDATE_ORGANIZATION_USERS:
      return {
        ...state,
        usersByOrgId: {
          ...state.usersByOrgId,
          [values.organizationId]: values.users.map((u) => u.id),
        },
      }

    default:
      return state
  }
}

export default organizationsReducer
