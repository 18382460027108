import { pick } from 'src/util'

import moment from 'src/moment'
import { apiGET, apiPOST, apiDELETE } from 'src/api'
import { emptyAction, payloadAction } from '../common'
import { showFormError } from '../form'

const TIMEZONE = 'Europe/Helsinki'

export const Organization = {
  FETCH: '@@organization/FETCH',
  RECEIVE: '@@organization/RECEIVE',
  FETCH_BOOK: '@@organization/FETCH_BOOK',
  FETCH_BOOKS: '@@organization/FETCH_BOOKS',
  RECEIVE_BOOKS: '@@organization/RECEIVE_BOOKS',
  REQUEST_FAIL: '@@organization/FETCH_BOOKS_REQUEST_FAILED',
  FETCH_USERS: '@@organization/FETCH_USERS',
  FETCH_DISTRIBUTIONS: '@@organization/FETCH_DISTRIBUTIONS',
  CREATE: '@@organization/CREATE',
  SAVE: '@@organization/SAVE',
  ADD_PUBLISHER: '@@organization/ADD_PUBLISHER',
  UPDATE_PUBLISHER: '@@organization/UPDATE_PUBLISHER',
  TOGGLE_BY_PRODUCTION_TYPE: '@@organization/TOGGLE_BY_PRODUCTION_TYPE',
}

export const OrganizationBook = {
  REQUEST: '@@organizationBook/REQUEST',
  RECEIVE: '@@organizationBook/RECEIVE',
  DELETE: '@@organizationBook/REQUEST_DELETE',
  FETCH_DISTRIBUTIONS: '@@organizationBook/REQUEST_DISTRIBUTIONS',
  CREATE: '@@organizationBook/CREATE',
  CREATED: '@@organizationBook/CREATED',
  SAVE: '@@organizationBook/SAVE',
  UPDATE: '@@organizationBook/UPDATE',
  UPDATE_AUDIO: '@@organizationBook/UPDATE_AUDIO',
  PLAY_AUDIO: '@@organizationBook/PLAY_AUDIO',
  DOWNLOAD_MASTER: '@@organizationBook/DOWNLOAD_MASTER',
  TOGGLE_CREATION: '@@organizationBook/TOGGLE_BOOK_CREATION',
  CLEAR_UPDATED: '@@organizationBook/CLEAR_UPDATED',
  CREATE_DISTRIBUTION: '@@organizationBook/CREATE_DISTRIBUTION',
  UPDATE_DISTRIBUTION: '@@organizationBook/UPDATE_DISTRIBUTION',
  DELETE_DISTRIBUTION: '@@organizationBook/DELETE_DISTRIBUTION',
  UPDATE_DISTRIBUTIONS: '@@organizationBook/UPDATE_DISTRIBUTIONS',
  FROM_SCRATCH: '@@organizationBook/FETCH_FROM_SCRATCH',
  DISTRIBUTE: '@@organizationBook/DISTRIBUTE',
  UPDATE_READER_COMMENTS: '@@organizationBook/UPDATE_READER_COMMENTS',
  UPDATE_LISTENER_COMMENTS: '@@organizationBook/UPDATE_LISTENER_COMMENTS',
}

export const OrganizationActions = {
  REQUEST_BOOKS: '@@organization/REQUEST_BOOKS',
}

export const Organizations = {
  FETCH: '@@organizations/FETCH',
  RECEIVE: '@@organizations/RECEIVE',
}

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const updateOrganization = payloadAction(UPDATE_ORGANIZATION)

export const STOP_FETCHING_BOOKS = 'STOP_FETCHING_BOOKS'
export const stopFetchingBooks = emptyAction(STOP_FETCHING_BOOKS)

export const SET_BOOKS = 'SET_BOOKS'

export const updateBook = payloadAction(OrganizationBook.RECEIVE)

export const REMOVE_BOOK = 'REMOVE_BOOK'
export const removeBook = payloadAction(REMOVE_BOOK)

export const UPDATE_AUDIO = 'UPDATE_AUDIO'
export const updateAudio = payloadAction(UPDATE_AUDIO)

export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT'
export const removeAttachment = payloadAction(REMOVE_ATTACHMENT)

export const PLAY_AUDIO = 'PLAY_AUDIO'
export const playAudio = payloadAction(PLAY_AUDIO)

export const updateDistribution = payloadAction(OrganizationBook.UPDATE_DISTRIBUTION)

export const REMOVE_DISTRIBUTION = 'REMOVE_DISTRIBUTION'
export const removeDistribution = payloadAction(REMOVE_DISTRIBUTION)

export const toggleBookCreation = emptyAction(OrganizationBook.TOGGLE_CREATION)

export const TOGGLE_ORGANIZATION_CREATION = 'TOGGLE_ORGANIZATION_CREATION'
export const toggleOrganizationCreation = emptyAction(TOGGLE_ORGANIZATION_CREATION)

export const TOGGLE_DISTRIBUTION_CREATION = 'TOGGLE_DISTRIBUTION_CREATION'
export const toggleDistributionCreation = emptyAction(TOGGLE_DISTRIBUTION_CREATION)

export const UPDATE_ORGANIZATION_USERS = 'UPDATE_ORGANIZATION_USERS'

const withAudio = (dispatch) => {
  const update = updateAudio(dispatch)
  return (chapter) => {
    const { isbn, number, duration } = chapter
    const id = `${isbn}:${number}`

    return new Promise((resolve, reject) => {
      if (chapter.url) {
        resolve(chapter)
      } else {
        apiGET(`/playurl/${isbn}/${number}`)
          .then(({ url, expires }) => {
            const audio = {
              id,
              isbn,
              number,
              duration,
              url,
              expiresAt: Date.now() + expires * 60000,
            }
            update(audio)
            resolve(audio)
          })
          .catch(reject)
      }
    })
  }
}

export const fetchAudio = (dispatch) => {
  const play = playAudio(dispatch)
  const fetchAudio = withAudio(dispatch)

  return (chapter) => {
    fetchAudio(chapter).then((audio) => play({ id: audio.id }))
  }
}

export const downloadAudio = (dispatch) => {
  const fetchAudio = withAudio(dispatch)

  return (chapter) => {
    fetchAudio(chapter).then((audio) => {
      window.open(audio.url)
    })
  }
}

//doesn't dispatch
export const downloadMaster = (organizationId, isbn) => {
  return apiGET(`/organizations/${organizationId}/audiobooks/${isbn}/master`).then(({ url }) => {
    window.open(url)
  })
}

// Create a whole new book for organization
export const createBook = (dispatch) => {
  const createAction = updateBook(dispatch)
  const toggleAction = toggleBookCreation(dispatch)
  const errorAction = showFormError(dispatch)

  return (book) => {
    const bookRecord = pick(book, [
      'isbn',
      'state',
      'name',
      'author',
      'translator',
      'narrator',
      'info',
      'year',
      'celiaRequested',
      'celiaStatus',
      'isBacklist',
      'productionModel',
      'corrections',
      'materialDelivery',
      'requestedDelivery',
      'verificationDone',
      'charCount',
      'pageCount',
      'organizationInfo',
      'verifier',
      'invoiced',
      'celiaInvoiced',
      'verifierSalaryPaid',
      'narratorSalaryPaid',
      'publisherId',
      'productionType',
    ])

    bookRecord.materialDelivery = book.materialDelivery ? new Date(book.materialDelivery) : undefined
    bookRecord.invoiced = book.invoiced ? new Date(book.invoiced) : undefined
    bookRecord.celiaInvoiced = book.celiaInvoiced ? new Date(book.celiaInvoiced) : undefined
    bookRecord.verifierSalaryPaid = book.verifierSalaryPaid ? new Date(book.verifierSalaryPaid) : undefined
    bookRecord.narratorSalaryPaid = book.narratorSalaryPaid ? new Date(book.narratorSalaryPaid) : undefined
    bookRecord.charCount = bookRecord.charCount ? parseInt(bookRecord.charCount) : undefined
    bookRecord.pageCount = bookRecord.pageCount ? parseInt(bookRecord.pageCount) : undefined

    return apiPOST(`/organizations/${book.organizationId}/audiobooks`, bookRecord)
      .then((newBook) => {
        toggleAction()
        errorAction({ form: 'book', message: '' })
        createAction({
          ...newBook,
          organizationId: book.organizationId,
          id: newBook.isbn,
        })
        return newBook
      })
      .catch((err) => {
        const message = err.response ? err.response.data.message : err.message
        errorAction({ form: 'book', message: message })
        console.error(err)
      })
  }
}

export const createDistribution = (dispatch) => {
  const createAction = updateDistribution(dispatch)
  const toggleAction = toggleDistributionCreation(dispatch)
  const errorAction = showFormError(dispatch)

  return (distribution) => {
    const distributionRecord = {
      scheduled: distribution.immediate ? undefined : moment(distribution.scheduled).tz(TIMEZONE).format(),
      immediate: distribution.immediate,
      message: distribution.message,
      distributors: distribution.distributors,
    }
    return apiPOST(
      `/organizations/${distribution.organizationId}/audiobooks/${distribution.isbn}/distributions`,
      distributionRecord
    )
      .then((newDistribution) => {
        toggleAction()
        errorAction({ form: 'distribution', message: '' })
        return createAction({
          ...newDistribution,
          organizationId: distribution.organizationId,
          bookIsbn: distribution.isbn,
        })
      })
      .catch((err) => {
        const message = err.response ? err.response.data.message : err.message
        errorAction({ form: 'distribution', message: message })
        console.error(err)
      })
  }
}

export const deleteDistribution = (dispatch) => {
  const removeAction = removeDistribution(dispatch)
  return (distribution) => {
    apiDELETE(`/organizations/${distribution.organizationId}/distributions/${distribution.id}`)
      .then(({ status }) => {
        if (status === 'OK') {
          removeAction(distribution)
        }
      })
      .catch(console.error)
  }
}

export const distributeImmediately = (dispatch) => {
  const errorAction = showFormError(dispatch)

  return (distribution) => {
    if (!distribution.hasChapters) {
      //book has no chapters, so book cannot publish immediately
      // -> publish when master is ready
      const normalDistribution = createDistribution(dispatch)
      return normalDistribution({ ...distribution, immediate: true })
    }

    const distributionRecord = {
      scheduled: moment(distribution.scheduled).tz(TIMEZONE).format(),
      message: distribution.message,
      distributors: distribution.distributors,
    }
    return apiPOST(
      `/organizations/${distribution.organizationId}/audiobooks/${distribution.isbn}/distribute`,
      distributionRecord
    )
      .then((response) => {
        if (response.state === 'OK') {
          errorAction({ form: 'distribution', message: '' })
        }
      })
      .catch((err) => {
        const message = err.response ? err.response.data.message : err.message
        errorAction({ form: 'distributeImmediately', message: message })
        console.error(err)
      })
  }
}
