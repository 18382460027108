import React from 'react'
import { useLocation } from 'react-router-dom'

/**
 * React router 5 doesn't have built in query
 * params support, remove when upgrade to v6
 */
export const useQueryParams = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
