const hsl = (hue, saturation, lightning) =>
  `hsl(${parseInt(hue, 10)}, ${parseInt(saturation, 10)}%, ${parseInt(lightning, 10)}%)`

export const theme = {
  colors: {
    white: '#fff',
    black: '#333',
    bookArea: '#262626',
    cream: hsl(0, 0, 95),
    creamMuted: hsl(0, 0, 75),
    creamMuted2: hsl(0, 0, 55),
    grayBackground: hsl(0, 0, 22, 1),
    greySecondary: hsl(0, 0, 32, 1),
    greyHighlight: hsl(0, 0, 42, 1),
    redHighlight: hsl(358, 95, 57),
    red: hsl(358, 95, 67),
    redActive: hsl(358, 95, 77),
    green: hsl(135, 68, 50),
    yellow: hsl(52, 98, 62),
    blue: hsl(205, 84, 53),
  },
  padding: {
    micro: '0.25rem',
    small: '0.5rem',
    normal: '1rem',
    big: '2rem',
  },
  column: {
    breakHeight: '3rem',
  },
  fontSize: {
    normal: '1rem',
    logout: '1.2rem',
    subtitle: '0.95rem',
    title: '1.8rem',
  },
  borderRadius: {
    subtle: '0.3rem',
    round: '10rem',
  },
  breakpoints: {
    mobile: '42rem',
  },
  minWidths: {
    button: '11rem',
    field: '16rem',
    chapter: '10rem',
  },
  maxWidths: {
    button: '16rem',
    column: '32rem',
    form: '50rem',
    page: '100%',
  },
}

export const reactSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: theme.colors.bookArea,
    color: theme.colors.cream,
    border: `1px solid ${theme.colors.cream}`,
    transition: `border-color 0.2s`,
    minWidth: '210px',
    maxWidth: '210px',
    padding: '0.5rem',
    margin: 0,
    borderRadius: 'none !important',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: `1px solid ${theme.colors.redActive} !important`,
    },
    '&:focus': {
      border: `1px solid ${theme.colors.red} !important`,
      outline: 'none',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: theme.colors.cream,
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? theme.colors.red : theme.colors.bookArea,
    color: theme.colors.cream,
  }),
  menuList: (styles) => ({
    ...styles,
    color: theme.colors.cream,
    backgroundColor: theme.colors.bookArea,
  }),
  placeholder: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  }),
}
