import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { apiGET, apiPUT, apiDELETE, apiPOST } from 'src/api'
import { WRITE_USER, UPDATE_USERS, REMOVE_USER, LOGOUT, Users } from 'store/actions/user'
import { firebaseAuth } from 'src/firebase'

export const providerLogout = (auth) => {
  const providerData = auth.currentUser ? auth.currentUser.providerData : null
  const provider = (providerData || []).map((profile) => {
    return profile.providerId
  })

  if (provider && provider.find(() => 'google')) {
    const providerPortal = window.open('https://accounts.google.com/logout', '_blank', 'height=500, width=500')
    setTimeout(() => {
      providerPortal.close()
    }, 1500)
    if (!providerPortal || providerPortal.closed || typeof providerPortal.closed == 'undefined') {
      console.log('BLOCKED')
    }
  }
}

function* logout() {
  const auth = yield firebaseAuth
  providerLogout(auth)
  yield auth.signOut()
  yield put({ type: LOGOUT })
}

function* fetchUsers(action) {
  const { userType } = action.payload
  const q = userType ? `?type=${userType}` : ''
  let res
  try {
    res = yield call(apiGET, `/users${q}`)
  } catch (e) {
    console.log('> Failed to fetch users', e)
  } finally {
    if (res && res.users) {
      // console.log('> Received users:', res.users)
      yield put({ type: UPDATE_USERS, payload: res.users })
    }
  }
}

function* deleteUser(action) {
  const { userId } = action.payload
  let res
  try {
    res = yield call(apiDELETE, `/users/${userId}`)
  } catch (e) {
    console.log('> Failed to delete users', e)
  } finally {
    if (res && res.status === 'OK') {
      console.log('> Deleted user:', userId)
      yield put({ type: REMOVE_USER, payload: userId })
    } else {
      console.log('> User not deleted...')
    }
  }
}

// Save all kind of users. The hourlySalary parameter is only for the reader users.
function* modifyUser(action) {
  const { userId, name, blocked, hourlySalary, productionTypes } = action.payload
  const userData = {
    blocked: blocked,
    displayName: name,
    productionTypes: productionTypes,
  }

  if (hourlySalary) {
    userData.hourlySalary = parseInt(hourlySalary)
  }

  try {
    const response = yield call(apiPUT, `/users/${userId}`, userData)

    yield put({ type: WRITE_USER, payload: response })
  } catch (e) {
    console.error(e)
  }
}

function* createExternalUser(action) {
  const { email, name, productionType } = action.payload
  const data = { name, nologin: true, type: 'READER', productionType }
  // Email is optional for external readers
  if (email) data.email = email
  let createdUser
  try {
    createdUser = yield call(apiPOST, '/users', data)
  } catch (e) {
    console.log('> Failed to create external user', e)
  } finally {
    console.log('> External reader user created', createdUser)
    yield put({ type: UPDATE_USERS, payload: [createdUser] })
  }
}

function* userSaga() {
  yield takeEvery(Users.FETCH, fetchUsers)
  yield takeLatest(Users.DELETE, deleteUser)
  yield takeLatest(Users.MODIFY, modifyUser)
  yield takeLatest(Users.INVITE_EXTERNAL, createExternalUser)
  yield takeLatest(Users.LOGOUT, logout)
}

export default userSaga
