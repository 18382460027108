import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiPOST } from '../../../api'
import { pronunciationsSelector } from '../../../store/selectors'
import { TTSAudioPayload, TTSReaderOptions } from '../../../typings'

export const useTts = (options: TTSReaderOptions) => {
  const [isSpeaking, setSpeaking] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const credentials = useSelector(pronunciationsSelector.getCredentials)
  const optionsRef = useRef<TTSReaderOptions>()
  const ttsGenderVoices = useSelector(pronunciationsSelector.getTtsGengerVoices)

  useEffect(() => {
    optionsRef.current = options
  }, [options.speakingRate, options.gender])

  /**
   * Cache for holding the audio data.
   */
  const cache = useRef<Record<string, string>>({})

  /**
   * Free cache objects on unload
   */
  useEffect(() => {
    return () => Object.values(cache.current).forEach((val) => window.URL.revokeObjectURL(val))
  }, [])

  const loadAudio = async (payload: TTSAudioPayload) => {
    setLoading(true)
    try {
      /**
       * Credentials needed and exists only
       * if visited via "reader link" that includes credentials
       */
      return await apiPOST(
        `/tts`,
        { ...payload, ...credentials },
        {
          responseType: 'blob',
        }
      )
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const createCacheId = (payload: TTSAudioPayload) => `${payload.text}${payload.languageCode}${payload.speakingRate}`

  const speak = async (text: string, language: string) => {
    const voice = ttsGenderVoices
      ?.find(({ languageCode }) => languageCode === language)
      ?.voices.filter(({ name }) => name.includes('-Standard-'))
      ?.sort((a, b) => a.name.split('-')[3].localeCompare(b.name.split('-')[3]))[0]

    if (!voice) {
      console.error('No TTS voice found')
      return
    }

    const opt = optionsRef.current || options
    const payload = { ...opt, text, languageCode: language, voice }
    const cacheId = createCacheId(payload)
    let audioUrl

    if (cache.current[cacheId]) {
      audioUrl = cache.current[cacheId]
    } else {
      const audioData = await loadAudio(payload)
      audioUrl = window.URL.createObjectURL(audioData)
      cache.current[cacheId] = audioUrl
    }

    const audio = new Audio(audioUrl)
    audio.onpause = () => setSpeaking(false)
    audio.onplay = () => setSpeaking(true)
    await audio.play()
  }

  return {
    speak,
    isSpeaking,
    isLoading,
  }
}
