import { createSelector } from 'reselect'

const getAttachments = (state) => state.models.attachments

export const getAttachmentsByBookId = createSelector([getAttachments], (attachments) => {
  return attachments.idsByBookId || []
})

export const getAttachmentsById = createSelector([getAttachments], (attachments) => {
  return attachments.byId || []
})

export const getAllAttachements = (book) =>
  createSelector([getAttachmentsById, getAttachmentsByBookId], (byId, idsByBookId) => {
    if (idsByBookId[book.id]) {
      return (
        idsByBookId[book.id] && idsByBookId[book.id].map((id) => byId[id]).filter((attachmentsIds) => !!attachmentsIds)
      )
    }
    return []
  })

export const readingMaterialSelector = (book) =>
  createSelector([getAllAttachements(book)], (attachments) => {
    return attachments.filter((a) => a.type === 'SPECIAL')
  })

export const attachmentsSelector = (book) =>
  createSelector([getAllAttachements(book)], (attachments) => {
    return attachments.filter((a) => a.type === 'NORMAL')
  })
