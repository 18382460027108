import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'

import { getUserSelector } from 'store/selectors'
import { UserType } from './pseudo-types'

const Reader = ({ component: Component, ...rest }) => {
  const user = useSelector(getUserSelector)
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (user.type) {
          case UserType.CELIA:
            return <Redirect to={{ pathname: `/celia`, state: {} }} />
          case UserType.READER:
            return <Component {...props} />
          case UserType.CORRECTIONS:
            return <Redirect to={{ pathname: `/corrections`, state: {} }} />
          case UserType.ADMIN:
            return <Redirect to={{ pathname: `/`, state: {} }} />
          default:
            return (
              <Redirect
                to={{
                  pathname: `/organizations/${user.organizationId}`,
                  state: { from: props.location },
                }}
              />
            )
        }
      }}
    />
  )
}

const Celia = ({ component: Component, ...rest }) => {
  const user = useSelector(getUserSelector)
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (user.type) {
          case UserType.CELIA:
            return <Component {...props} />
          case UserType.READER:
            return <Redirect to={{ pathname: `/reader`, state: {} }} />
          case UserType.CORRECTIONS:
            return <Redirect to={{ pathname: `/corrections`, state: {} }} />
          case UserType.ADMIN:
            return <Redirect to={{ pathname: `/`, state: {} }} />
          default:
            return (
              <Redirect
                to={{
                  pathname: `/organizations/${user.organizationId}`,
                  state: { from: props.location },
                }}
              />
            )
        }
      }}
    />
  )
}

const CorrectionsListener = ({ component: Component, ...rest }) => {
  const user = useSelector(getUserSelector)
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (user.type) {
          case UserType.CELIA:
            return <Redirect to={{ pathname: `/celia`, state: {} }} />
          case UserType.READER:
            return <Redirect to={{ pathname: `/reader`, state: {} }} />
          case UserType.CORRECTIONS:
            return <Component {...props} />
          case UserType.ADMIN:
            return <Redirect to={{ pathname: `/`, state: {} }} />
          default:
            return (
              <Redirect
                to={{
                  pathname: `/organizations/${user.organizationId}`,
                  state: { from: props.location },
                }}
              />
            )
        }
      }}
    />
  )
}

const Guest = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

const ReaderRoute = withRouter(Reader)
const CeliaRoute = withRouter(Celia)
const CorrectionsListenerRoute = withRouter(CorrectionsListener)
const GuestRoute = withRouter(Guest)

export { ReaderRoute, CeliaRoute, CorrectionsListenerRoute, GuestRoute }
