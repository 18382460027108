export function addIfNotExist(ids, newId) {
  const index = ids.indexOf(newId)
  if (index !== -1) {
    return ids
  } else {
    return [newId, ...ids]
  }
}

export function remove(ids, id) {
  return ids.filter((i) => i !== id)
}

export function addMissing(existingIds, newIds) {
  return [...new Set(existingIds.concat(newIds))]
}

export function updateWithGroupedBy(groupAttribute, valueAttribute = 'id') {
  return (map, value) => {
    const key = value[groupAttribute]
    const newValue = addIfNotExist(map[key] || [], value[valueAttribute])
    return { ...map, [key]: newValue }
  }
}
export const updateManyWithGroupedBy = (groupAttribute) => {
  return (map, values) => {
    return values.reduce(updateWithGroupedBy(groupAttribute), map)
  }
}

export function removeWithId(map, id) {
  return { ...map, [id]: undefined }
}

export function convertToMap(books) {
  return books.reduce(function (map, book) {
    map[book.isbn] = { ...book, id: book.isbn }
    return map
  }, {})
}

export function convertToOrganizationMap(books) {
  return books.reduce(function (map, book) {
    const arr = map[book.organizationId] || []
    arr.push(book.isbn)
    map[book.organizationId] = arr
    return map
  }, {})
}

export function updateWithId(map, value) {
  return { ...map, [value.id]: value }
}

export function updateManyWithId(map, objects) {
  return objects.reduce(updateWithId, map)
}
