import { css } from 'styled-components'
import { theme } from '../../theme'
import styled from 'styled-components'

/**
 * Contains the styled components used with Toastify notifications.
 *
 */

export const commonToastOptions = {
  className: css({
    background: `${theme.colors.grayBackground} !important`,
    borderRadius: '4px !important',
    padding: '16px !important',
    boxShadow: '0px 8px 32px 12px rgba(0,0,0,0.5) !important',
    border: `1px solid ${theme.colors.red}`,
  }),
  bodyClassName: css({
    color: theme.colors.cream,
  }),
  draggable: false,
}

export const Toast = styled.div`
  display: flex;
  flex-direction: column;
`

export const Heading = styled.h3`
  margin-bottom: 8px;
  margin-top: 0px;
  font-size: 16px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.red};
  line-height: 1.4;
`

export const Text = styled.p`
  font-size: 14px;
  margin: 12px 0px;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const ToastRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ApiStatus = styled.span`
  padding: 4px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.colors.red};
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 12px;
`
