import { createSelector } from 'reselect'

const userSelector = (state) => state.user

const createUserSelector = (type) =>
  createSelector([userSelector], (user) => {
    switch (type) {
      case 'ADMIN':
      case 'READER':
      case 'ORGANIZATION':
      case 'CELIA':
      case 'CORRECTIONS':
        return Object.values(user.byId).filter((u) => !!u && u.type === type)
    }
  })

export const getAdminUsers = createUserSelector('ADMIN')
export const getReaderUsers = createUserSelector('READER')
export const getUnblockedReaderUsers = createSelector([userSelector], (user) => {
  return Object.values(user.byId).filter((u) => !!u && u.type === 'READER' && u.blocked === false)
})
export const getOrgUsers = createUserSelector('ORGANIZATION')
export const getCeliaUsers = createUserSelector('CELIA')
export const getCorrectionsUsers = createUserSelector('CORRECTIONS')

export const isAdminSelector = (state) => state.user.admin
export const getUserSelector = (state) => state.user
export const isLoggedIn = (state) => state.user.state === 'LOGGED_IN'
