import styled from 'styled-components'

export const Button = styled.button`
  font-size: ${(p) => p.theme.fontSize.normal};
  padding: ${(p) => p.theme.padding.small};
  background: ${(p) => p.theme.colors.cream};
  &:hover,
  &:focus {
    background: ${(p) => p.theme.colors.creamActive};
  }
  &:disabled {
    opacity: 0.5;
  }
  color: ${(p) => p.theme.colors.black};
  border: none;
  flex: 1;
  max-width: ${(p) => p.theme.maxWidths.button};
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
`

export const SecondaryButton = styled(Button)`
  color: ${(p) => p.theme.colors.cream};
  background: transparent;
  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors.creamActive};
    background: transparent;
  }
`
export const GreyButton = styled(Button)`
  color: ${(p) => p.theme.colors.cream};
  background: ${(p) => p.theme.colors.greySecondary};
  &:hover,
  &:focus {
    background: ${(p) => p.theme.colors.greyHighlight};
  }
`

export const DangerButton = styled(Button)`
  color: ${(p) => p.theme.colors.cream};
  background: ${(p) => p.theme.colors.red};
  min-width: ${(p) => p.theme.minWidths.button};
  &:hover,
  &:focus {
    background: ${(p) => p.theme.colors.redHighlight};
  }
`
export const DangerButtonSmall = styled(Button)`
  color: ${(p) => p.theme.colors.cream};
  background: ${(p) => p.theme.colors.red};
  min-width: 70px;
  &:hover,
  &:focus {
    background: ${(p) => p.theme.colors.redHighlight};
  }
`

export const DangerButtonBorder = styled(Button)`
  color: ${(p) => p.theme.colors.cream};
  border: 3px solid white;
  background: ${(p) => p.theme.colors.red};
  &:hover,
  &:focus {
    background: ${(p) => p.theme.colors.redHighlight};
  }
`

export const ButtonLink = styled.button`
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  cursor: pointer;
  display: inline;
  font: inherit;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
  color: ${(p) => p.theme.colors.cream};
  &:hover {
    color: ${(p) => p.theme.colors.redActive};
  }
`
