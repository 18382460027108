import React from 'react'
import { Heading, Text, Toast } from './StyledToasts'

const SuccessToast = ({ title, text }) => (
  <Toast>
    <Heading>{title}</Heading>
    <Text>{text}</Text>
  </Toast>
)

export default SuccessToast
