export const UserType = {
  ADMIN: 'ADMIN',
  CELIA: 'CELIA',
  READER: 'READER',
  CORRECTIONS: 'CORRECTIONS',
  ORGANIZATION: 'ORGANIZATION',
  GUEST: 'GUEST',
}

export const Views = {
  ADMIN: 'ADMIN',
  ADMIN_BOOK_LIST: 'ADMIN_BOOK_LIST',
  ORGANIZATION: 'ORGANIZATION',
  ORGANIZATION_BOOK: 'ORGANIZATION_BOOK',
  READER: 'READER',
  CELIA: 'CELIA',
  CORRECTIONS: 'CORRECTIONS',
  INVITATION: 'INVITATION',
}

export const ListRowType = {
  EXPANDING: 0,
  LINK: 1,
  PLAIN: 2,
}

export const AdminRowState = {
  NORMAL: 0,
  EDIT: 1,
  EXPAND: 2,
  EDIT_AND_EXPAND: 3,
}

export const Action = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
}

export const CeliaStatus = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NOT_SET: 'NOT_SET',
}

export const CorrectionsStatus = {
  UNCLAIMED: 'UNCLAIMED',
  CLAIMED: 'CLAIMED',
  COMPLETED: 'COMPLETED',
}

export const ReaderCandidateStates = {
  Unconfirmed: 'UNCONFIRMED',
  Open: 'OPEN',
  Accepted: 'ACCEPTED',
  Rejected: 'REJECTED',
  Reading: 'READING',
  Done: 'DONE',
}

export const PronunciationGuideStatus = {
  NONE: 'NONE',
  DRAFT: 'DRAFT',
  LIVE: 'LIVE',
  INITIALIZING: 'INITIALIZING',
}
